// Malagasy
import { LocaleData } from 'ngx-bootstrap/chronos';

export const mgLocale: LocaleData = {
    months: ['Janoary', 'Febroary', 'Martsa', 'Avrily', 'Mey', 'Jona', 'Jolay', 'Aogositra', 'Septambra', 'Oktobra', 'Novambra', 'Desambra'],
    monthsShort: ['Jan', 'Feb', 'Mar', 'Avr', 'Mey', 'Jon', 'Jol', 'Aog', 'Sep', 'Okt', 'Nov', 'Des'],
    weekdays: ['Alahady', 'Alatsinainy', 'Talata', 'Alarobia', 'Alakamisy', 'Zoma', 'Sabotsy'],
    weekdaysShort: ['Ala', 'Ala', 'Tal', 'Ala', 'Ala', 'Zom', 'Sab'],
    weekdaysMin: ['Al', 'Al', 'Ta', 'Al', 'Al', 'Zo', 'Sa'],
    longDateFormat: {
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY LT',
        LLLL: 'dddd, D MMMM YYYY LT',
        LT: 'HH:mm',
        LTS: 'HH:mm:ss'
    },
    calendar: {
        sameDay: '[Anio] LT',
        nextDay: '[Rahampitso] LT',
        nextWeek: 'dddd [ho avy] LT',
        lastDay: '[Omaly] LT',
        lastWeek: '[Tamin\'ny] dddd [herinandro] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: 'aorian\'ny %s',
        past: '%s lasa',
        s: 'segondra vitsy',
        m: 'minitra iray',
        mm: '%d minitra',
        h: 'ora iray',
        hh: '%d ora',
        d: 'andro iray',
        dd: '%d andro',
        M: 'volana iray',
        MM: '%d volana',
        y: 'taona iray',
        yy: '%d taona'
    },
    ordinal: (number: number) => `${number}`,
    week: {
        dow: 1,
        doy: 4
    }
};
