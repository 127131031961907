// Tatar
import { LocaleData } from 'ngx-bootstrap/chronos';

export const ttLocale: LocaleData = {
    months: ['гыйнвар', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'],
    monthsShort: ['гыйн', 'фев', 'март', 'апр', 'май', 'июнь', 'июль', 'авг', 'сен', 'окт', 'ноя', 'дек'],
    weekdays: ['якшәмбе', 'дүшәмбе', 'сишәмбе', 'чәршәмбе', 'пәнҗешәмбе', 'җомга', 'шимбә'],
    weekdaysShort: ['якш', 'дүш', 'сиш', 'чәр', 'пән', 'җом', 'шим'],
    weekdaysMin: ['як', 'дүш', 'сиш', 'чәр', 'пән', 'җом', 'шим'],
    longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD.MM.YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'dddd, D MMMM YYYY HH:mm'
    },
    calendar: {
        sameDay: '[бүген] LT',
        nextDay: '[иртә] LT',
        nextWeek: 'dddd [көн] LT',
        lastDay: '[кичә] LT',
        lastWeek: '[үткән] dddd [көн] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: '%s соң',
        past: '%s элек',
        s: 'берничә секунд',
        ss: '%d секунд',
        m: 'бер минут',
        mm: '%d минут',
        h: 'бер сәгать',
        hh: '%d сәгать',
        d: 'бер көн',
        dd: '%d көн',
        M: 'бер ай',
        MM: '%d ай',
        y: 'бер ел',
        yy: '%d ел'
    },
    ordinal: (num: number): string => `${num}-нче`,
    week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4  // The week that contains Jan 4th is the first week of the year.
    }
};
