import { ViewportScroller } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  ViewChildren
} from '@angular/core';
import {
  LABELS,
  LanguageService,
  NO_WALKTHROUGH_ELEMENT,
  WalkthroughToolTipElement,
  WalkthroughToolTipOptions,
  WalkthroughToolTipService,
  isWalkthroughToolTipElement
} from '@services/public';
import { Subscription } from 'rxjs';

const SCREEN_BUFFER: string = '3rem';
const TRIANGLE_BUFFER: number = 14;
const SCROLL_BUFFER: number = 100;

@Component({
  selector: 'app-walkthrough-tooltip',
  templateUrl: './walkthrough-tooltip.component.html',
  styleUrls: ['./walkthrough-tooltip.component.scss']
})
export class WalkthroughToolTipComponent implements
  OnInit, AfterViewInit, OnDestroy
{

  @ViewChildren('popup', { read: ElementRef })
  private _popups: QueryList<ElementRef>;

  options: WalkthroughToolTipOptions;
  element: WalkthroughToolTipElement;
  popupStyle: { [key: string]: any } = {};
  triangleStyle: { [key: string]: any } = {};
  clickElementStyle: { [key: string]: any } = {};

  private _popup: ElementRef;
  private _subscriptions: Subscription = new Subscription();

  // Page langauge.
  labels: {[key: string]: string} = {
    [LABELS.BACK]: '',
    [LABELS.NEXT]: ''
  }

  constructor(
    private _languageSvc: LanguageService,
    private _renderer: Renderer2,
    private _viewportScroller: ViewportScroller,
    private _walkthroughSvc: WalkthroughToolTipService
  ) {
    this._subscriptions.add(this._walkthroughSvc.optionsObservable.subscribe(
      options => {
        this.options = options;
        if (!this.options) {
          this._renderer.removeClass(document.body, 'walkthrough');
        } else this._renderer.addClass(document.body, 'walkthrough');
        this.element = (
          isWalkthroughToolTipElement(this.options) ?
          this.options.position.elementPosition :
          NO_WALKTHROUGH_ELEMENT
        );
        this._updatePopup();
      }
    ));
    this._subscriptions.add(this._walkthroughSvc.elementObservable.subscribe(
      element => {
        this.element = element ?? NO_WALKTHROUGH_ELEMENT;
        this._updatePopup();
      }
    ));

  }

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.getLabels(this.labels);
  }

  ngAfterViewInit(): void {
    this._subscriptions.add(this._popups.changes.subscribe(
      (popups: QueryList<ElementRef>) => {
        setTimeout(() => {
          this._popup = popups.first;
          this._scroll();
        });
      }
    ));
    this._popup = this._popups.first;
    this._scroll();
  }

  onClick(): void {
    this.options.next();
  }

  private _updatePopup(): void {
    if (!this.options) return;
    if (
      isWalkthroughToolTipElement(this.options) &&
      (
        this.element.top === this.element.bottom ||
        this.element.left === this.element.right
      )
    ) return;
    this._setPopupPosition();
    this._setTrianglePosition();
    this._setClickElementPosition();
  }

  private _setPopupPosition(): void {
    const style: any = {};
    if (!this.options) {
      this.popupStyle = style;
      return;
    }

    if (this.options.position.type === 'screen') {
      style.position = 'fixed';
      if (this.options.position.position.includes('top')) {
        style.top = SCREEN_BUFFER;
        style['margin-bottom'] = SCREEN_BUFFER;
      } else if (this.options.position.position.includes('bottom')) {
        style.bottom = SCREEN_BUFFER;
        style['margin-top'] = SCREEN_BUFFER;
      } else {
        style.top = 'calc(50% - ' + SCREEN_BUFFER + ')';
        style['margin-top'] = SCREEN_BUFFER;
        style['margin-bottom'] = SCREEN_BUFFER;
        style.transform = 'translateY(-50%)';
      }

      if (this.options.position.position.includes('left')) {
        style.left = SCREEN_BUFFER;
        style['margin-right'] = SCREEN_BUFFER;
      } else if (this.options.position.position.includes('right')) {
        style.right = SCREEN_BUFFER;
        style['margin-left'] = SCREEN_BUFFER;
      } else {
        style.left = 'calc(50% - ' + SCREEN_BUFFER + ')';
        style['margin-left'] = SCREEN_BUFFER;
        style['margin-right'] = SCREEN_BUFFER;
        style.transform = 'translateX(-50%)';
      }

      if (this.options.position.position === 'center') {
        style.transform = 'translate(-50%, -50%)';
      }

      if (this.options.displaySize) {
        style['font-size'] = this.options.displaySize;
      }
    }

    if (this.options.position.type === 'element') {
      switch (this.options.position.position) {
        case 'top': {
          style.bottom = 'calc(100% - ' + (
            this.element.top - TRIANGLE_BUFFER
          ) + 'px)';
          style.left = ((this.element.left + this.element.right) / 2) + 'px';
          style.transform = 'translateX(-50%)';
          break;
        }
        case 'left': {
          style.right = 'calc(100% - ' + (
            this.element.left - TRIANGLE_BUFFER
          ) + 'px)';
          style.top = ((this.element.top + this.element.bottom) / 2) + 'px';
          style.transform = 'translateY(-50%)';
          break;
        }
        case 'right': {
          style.left = (this.element.right + TRIANGLE_BUFFER) + 'px';
          style.top = ((this.element.top + this.element.bottom) / 2) + 'px';
          style.transform = 'translateY(-50%)';
          break;
        }
        case 'bottom': {
          style.top = (this.element.bottom + TRIANGLE_BUFFER) + 'px';
          style.left = ((this.element.left + this.element.right) / 2) + 'px';
          style.transform = 'translateX(-50%)';
          break;
        }
      }
      if (this.options.displaySize) {
        style['font-size'] = this.options.displaySize;
      }
    }

    this.popupStyle = style;
    setTimeout(this._scroll);
  }

  private _setTrianglePosition(): void {
    const style: any = {};
    if (!this.options || this.options.position.type !== 'element') {
      this.triangleStyle = style;
      return;
    }

    switch (this.options.position.position) {
      case 'top': {
        style.bottom = 'calc(100% - ' + this.element.top + 'px)';
        style.left = ((this.element.left + this.element.right) / 2) + 'px';
        style.transform = 'translateX(-50%)';
        break;
      }
      case 'left': {
        style.right = 'calc(100% - ' + this.element.left + 'px)';
        style.top = ((this.element.top + this.element.bottom) / 2) + 'px';
        style.transform = 'translateY(-50%)';
        break;
      }
      case 'right': {
        style.left = this.element.right + 'px';
        style.top = ((this.element.top + this.element.bottom) / 2) + 'px';
        style.transform = 'translateY(-50%)';
        break;
      }
      case 'bottom': {
        style.top = this.element.bottom + 'px';
        style.left = ((this.element.left + this.element.right) / 2) + 'px';
        style.transform = 'translateX(-50%)';
        break;
      }
    }

    this.triangleStyle = style;
  }

  private _setClickElementPosition(): void {
    const style: any = {};
    if (!this.options || !this.options.clickElement) return;
    const clickElement = document.querySelector(
      this.options.clickElement
    ).getBoundingClientRect() ?? NO_WALKTHROUGH_ELEMENT;
    style.bottom = (
      'calc(100% - ' +
      (clickElement.bottom + this._viewportScroller.getScrollPosition()[1]) +
      'px)'
    );
    style.left = (
      (clickElement.left + this._viewportScroller.getScrollPosition()[0]) +
      'px'
    );
    style.right = (
      'calc(100% - ' +
      (clickElement.right + this._viewportScroller.getScrollPosition()[0]) +
      'px)'
    );
    style.top = (
      (clickElement.top + this._viewportScroller.getScrollPosition()[1]) +
      'px'
    );
    this.clickElementStyle = style;
  }

  private _scroll: () => void = () => {
    if (
      !this.options ||
      this.options.position.type !== 'element' ||
      !!this.options.position.disableScroll ||
      !this._popup
    ) return;
    let top: number = Math.max(
      this.element.top -
      (this.options.position.scrollBuffer ?? SCROLL_BUFFER),
      0
    );
    if (!this.options.position.scrollBuffer) {
      top = Math.min(
        top,
        Math.max(
          this._popup.nativeElement.getBoundingClientRect().top +
          this._viewportScroller.getScrollPosition()[1] -
          SCROLL_BUFFER,
          0
        )
      );
    }
    window.scrollTo({
      top: top,
      behavior: 'smooth'
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
    this._renderer.removeClass(document.body, 'walkthrough');
  }

}
