// Maori
import { LocaleData } from 'ngx-bootstrap/chronos';

export const miLocale: LocaleData = {
    months: ['Kohi-tāte', 'Hui-tanguru', 'Poutū-te-rangi', 'Paenga-whāwhā', 'Haratua', 'Pipiri', 'Hōngongoi', 'Akuhata', 'Mahuru', 'Whiringa-ā-nuku', 'Whiringa-ā-rangi', 'Hakihea'],
    monthsShort: ['Koh', 'Hui', 'Pou', 'Pae', 'Har', 'Pip', 'Hōn', 'Aku', 'Mah', 'Whi', 'Whi', 'Hak'],
    weekdays: ['Rātū', 'Rāapa', 'Rāpare', 'Rāmere', 'Rāhoroi', 'Rātapu', 'Rāhina'],
    weekdaysShort: ['Rāt', 'Rāa', 'Rāp', 'Rām', 'Rāh', 'Rāt', 'Rāh'],
    weekdaysMin: ['R', 'R', 'R', 'R', 'R', 'R', 'R'],
    longDateFormat: {
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY LT',
        LLLL: 'dddd, D MMMM YYYY LT',
        LT: 'HH:mm',
        LTS: 'HH:mm:ss'
    },
    calendar: {
        sameDay: '[Ānei] LT',
        nextDay: '[Āpōpō] LT',
        nextWeek: 'dddd [ā muri] LT',
        lastDay: '[Inanahi] LT',
        lastWeek: '[I te] dddd [kia] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: 'i roto i %s',
        past: '%s i mua',
        s: 'ngā pūngao iti',
        m: 'tahi meneti',
        mm: '%d meneti',
        h: 'tahi haora',
        hh: '%d haora',
        d: 'tahi rā',
        dd: '%d rā',
        M: 'tahi marama',
        MM: '%d marama',
        y: 'tahi tau',
        yy: '%d tau'
    },
    ordinal: (number: number) => `${number}`,
    week: {
        dow: 1,
        doy: 4
    }
};
