// Akan
import { LocaleData } from 'ngx-bootstrap/chronos';

export const akLocale: LocaleData = {
    months: ['Sanda', 'Oforisuo', 'Ebɔw', 'Eban', 'Kwakuo', 'Kɔtɔnim', 'Aduanu', 'Dabɔ', 'Fɛbrɛ', 'Oktobɛ', 'Mɛbrɛ', 'Afɔbɛ'],
    monthsShort: ['San', 'Ofo', 'Ebo', 'Eba', 'Kwak', 'Kɔt', 'Adu', 'Dab', 'Fɛb', 'Okt', 'Mɛb', 'Afɔ'],
    weekdays: ['Kwesida', 'Dwowda', 'Benada', 'Wukuda', 'Yawoada', 'Fida', 'Memeneda'],
    weekdaysShort: ['Kwe', 'Dwo', 'Ben', 'Wuk', 'Yaw', 'Fid', 'Mem'],
    weekdaysMin: ['Kwe', 'Dwo', 'Ben', 'Wuk', 'Yaw', 'Fid', 'Mem'],
    longDateFormat: {
        LT: 'A h:mm',
        LTS: 'A h:mm:ss',
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY A h:mm',
        LLLL: 'dddd, D MMMM YYYY A h:mm'
    },
    calendar: {
        sameDay: '[Ɛnnɛ] LT',
        nextDay: '[ɛkyɛ] LT',
        nextWeek: 'dddd [da] LT',
        lastDay: '[ɛnnɛ] LT',
        lastWeek: '[Akwan no] dddd [da] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: '%s mu',
        past: '%s a atɔ',
        s: 'nkɔmɔ a ɛwɔ hɔ',
        ss: '%d nkɔmɔ',
        m: 'minit baako',
        mm: '%d minit',
        h: 'ɔsram baako',
        hh: '%d ɔsram',
        d: 'da baako',
        dd: '%d da',
        M: 'bosome baako',
        MM: '%d bosome',
        y: 'afe baako',
        yy: '%d afe'
    },
    ordinal: (num: number): string => `${num}`,
    week: {
        dow: 0, // Sunday is the first day of the week.
        doy: 6  // The week that contains Jan 6th is the first week of the year.
    }
};
