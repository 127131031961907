<div class="walkthrough-card" [ngClass]="slide.isLast? 'last': ''">
  <div class="walkthrough-card-content checklist">
    <div class="walkthrough-card-header">
      <div class="walkthrough-card-header-title">
        <h1>{{slide.title}}</h1>
      </div>
      <div *ngIf="slide.isLast" class="checklist-progress">
        <div class="checklist-progress-bar-bg">
          <div class="checklist-progress-bar" [style.width.%]="(absoluteValueOfDays/7) * 100"></div>
        </div>
      </div>
      <div *ngIf="!slide.isLast" class="walkthrough-card-subtitle">
        <p>{{slide.subTitle}}</p>
      </div>
      <div *ngIf="slide.isLast" class="walkthrough-card-subtitle">
        <p>{{slide.subTitle}}{{slide.daysTillStart}}</p>
      </div>
    </div>
    <div class="walkthrough-card-body">
      <ul class="checklist-list">
        <li *ngFor="let item of slide.checklistItems; let index=index">
          <div class="checklist-item">
            <div class="checklist-item-icon" [style]="'animation-delay:'+.3*index+'s'" [ngClass]="item.completed? 'icon-checked': 'icon-border animate__animated animate__rubberBand'">
              <i *ngIf="!item.completed" ></i>
              <img *ngIf="item.completed" [style]="'animation-delay:'+.3*index+'s'" src="assets/img/walkthrough/checked.svg"/>
            </div>
            <div class="checklist-item-text"><span>{{item.text}}</span>
              <ul *ngIf="item.hasOwnProperty('subItems')" class="sub-items">
                <li *ngFor="let sub of item.subItems" [class]="sub.complete? 'strike': ''">{{sub.text}}</li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
      <div *ngIf="!slide.isLast" class="checklist-progress">
        <div class="checklist-progress-bar-bg">
          <div class="checklist-progress-bar" [style.width.%]="(slide.itemsCompleted/slide.checklistItems.length) * 100"></div>
        </div>
        <div class="checklist-progress-text">{{slide.itemsCompleted}}/{{slide.checklistItems.length}}</div>
      </div>
      <div class="walkthrough-card-footer">
        <button class="btn btn-primary walkthrough-button" [ngClass]="absoluteValueOfDays === 7? 'green': ''" (click)="slide.buttonType == 'continue'? onClose() :onNext()">{{slide.buttonLabel}}</button>
        <div class="message"><h6>{{slide.message}}</h6></div>
      </div>
    </div>
  </div>
</div>
