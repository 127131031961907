// Luxembourgish
import { LocaleData } from 'ngx-bootstrap/chronos';

export const lbLocale: LocaleData = {
    months: ['Januar', 'Februar', 'Mäerz', 'Abrëll', 'Mee', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
    monthsShort: ['Jan', 'Feb', 'Mär', 'Abr', 'Mee', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
    weekdays: ['Sonndeg', 'Méindeg', 'Dënschdeg', 'Mëddwoch', 'Donneschdeg', 'Freideg', 'Samschdeg'],
    weekdaysShort: ['Son', 'Méi', 'Dën', 'Mëd', 'Don', 'Fre', 'Sam'],
    weekdaysMin: ['So', 'Me', 'Dë', 'Më', 'Do', 'Fr', 'Sa'],
    longDateFormat: {
        L: 'DD/MM/YYYY',
        LL: 'D. MMMM YYYY',
        LLL: 'D. MMMM YYYY LT',
        LLLL: 'dddd, D. MMMM YYYY LT',
        LT: 'HH:mm',
        LTS: 'HH:mm:ss'
    },
    calendar: {
        sameDay: '[Haut] LT',
        nextDay: '[Mëdet] LT',
        nextWeek: 'dddd [de] LT',
        lastDay: '[Gëschter] LT',
        lastWeek: '[Leschte] dddd [de] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: 'an %s',
        past: '%s vird',
        s: 'e puer Sekonnen',
        m: 'eng Minutt',
        mm: '%d Minutten',
        h: 'eng Stonn',
        hh: '%d Stonnen',
        d: 'e Dag',
        dd: '%d Deeg',
        M: 'e Mount',
        MM: '%d Méint',
        y: 'e Joer',
        yy: '%d Joer'
    },
    ordinal: (number: number) => `${number}`,
    week: {
        dow: 1,
        doy: 4
    }
};
