// Tamil
import { LocaleData } from 'ngx-bootstrap/chronos';

export const taLocale: LocaleData = {
    months: ['ஜனவரி', 'பிப்ரவரி', 'மார்ச்', 'ஏப்ரல்', 'மே', 'ஜூன்', 'ஜூலை', 'ஆகஸ்ட்', 'செப்டம்பர்', 'அக்டோபர்', 'நவம்பர்', 'டிசம்பர்'],
    monthsShort: ['ஜன', 'பிப', 'மார்ச்', 'ஏப்ரல்', 'மே', 'ஜூன்', 'ஜூலை', 'ஆகஸ்ட்', 'செப்', 'அக்', 'நவ', 'டிச'],
    weekdays: ['ஞாயிறு', 'திங்கள்', 'செவ்வாய்', 'புதன்', 'வியாழன்', 'வெள்ளி', 'சனி'],
    weekdaysShort: ['ஞா', 'தி', 'செ', 'பு', 'விய', 'வெ', 'ச'],
    weekdaysMin: ['ஞா', 'தி', 'செ', 'பு', 'விய', 'வெ', 'ச'],
    longDateFormat: {
        LT: 'A h:mm',
        LTS: 'A h:mm:ss',
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY, A h:mm',
        LLLL: 'dddd, D MMMM YYYY, A h:mm'
    },
    calendar: {
        sameDay: '[இன்று] LT',
        nextDay: '[நாளை] LT',
        nextWeek: 'dddd [நாள்] LT',
        lastDay: '[நேற்று] LT',
        lastWeek: '[கடந்த] dddd [நாள்] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: '%s இல்',
        past: '%s முன்',
        s: 'ஒரு சில வினாடிகள்',
        ss: '%d வினாடிகள்',
        m: 'ஒரு நிமிடம்',
        mm: '%d நிமிடங்கள்',
        h: 'ஒரு மணி நேரம்',
        hh: '%d மணி நேரம்',
        d: 'ஒரு நாள்',
        dd: '%d நாட்கள்',
        M: 'ஒரு மாதம்',
        MM: '%d மாதங்கள்',
        y: 'ஒரு வருடம்',
        yy: '%d ஆண்டுகள்'
    },
    ordinal: (num: number): string => `${num}வது`,
    week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4  // The week that contains Jan 4th is the first week of the year.
    }
};
