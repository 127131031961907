// Kannada

import { LocaleData } from "ngx-bootstrap/chronos";

export const knLocale: LocaleData = {
  months: ['ಜಾನವರಿ', 'ಫೆಬ್ರವರಿ', 'ಮಾರ್ಚ್', 'ಎಪ್ರಿಲ್', 'ಮೇ', 'ಜೂನ್', 'ಜುಲೈ', 'ಆಗಸ್ಟ್', 'ಸೆಪ್ಟೆಂಬರ್', 'ಅಕ್ಟೋಬರ್', 'ನವೆಂಬರ್', 'ಡಿಸೆಂಬರ್'],
  monthsShort: ['ಜಾನ್', 'ಫೆಬ್ರು', 'ಮಾರ್ಚ್', 'ಎಪ್ರಿಲ್', 'ಮೇ', 'ಜೂನ್', 'ಜುಲೈ', 'ಆಗ', 'ಸೆಪ್ಟೆ', 'ಅಕ್ಟೋ', 'ನವೆ', 'ಡಿಸೆ'],
  weekdays: ['ಭಾನುವಾರ', 'ಸೋಮವಾರ', 'ಮಂಗಲವಾರ', 'ಬುಧವಾರ', 'ಗುರುವಾರ', 'ಶುಕ್ರವಾರ', 'ಶನಿವಾರ'],
  weekdaysShort: ['ಭಾನು', 'ಸೋಮ', 'ಮಂಗ', 'ಬುಧ', 'ಗುರು', 'ಶುಕ್ರ', 'ಶನಿ'],
  weekdaysMin: ['ಭಾ', 'ಸೋ', 'ಮಂಗ', 'ಬು', 'ಗು', 'ಶು', 'ಶನಿ'],
  longDateFormat: {
      L: 'DD/MM/YYYY',
      LL: 'D MMMM YYYY',
      LLL: 'D MMMM YYYY LT',
      LLLL: 'dddd, D MMMM YYYY LT',
      LT: 'HH:mm',
      LTS: 'HH:mm:ss'
  },
  calendar: {
      sameDay: '[ಇಂದು] LT',
      nextDay: '[ನಾಳೆ] LT',
      nextWeek: 'dddd [ದಿನ] LT',
      lastDay: '[ನಿನ್ನೆ] LT',
      lastWeek: '[ಹಿಂದಿನ] dddd [ದಿನ] LT',
      sameElse: 'L'
  },
  relativeTime: {
      future: '%s ನಂತರ',
      past: '%s ಹಿಂದೆ',
      s: 'ಕೆಲವು ಸೆಕೆಂಡುಗಳು',
      m: 'ಒಂದು ನಿಮಿಷ',
      mm: '%d ನಿಮಿಷ',
      h: 'ಒಂದು ಗಂಟೆ',
      hh: '%d ಗಂಟೆ',
      d: 'ಒಂದು ದಿನ',
      dd: '%d ದಿನಗಳು',
      M: 'ಒಂದು ತಿಂಗಳು',
      MM: '%d ತಿಂಗಳು',
      y: 'ಒಂದು ವರ್ಷ',
      yy: '%d ವರ್ಷ'
  },
  ordinal: (number: number) => `${number}`,
  week: {
      dow: 1,
      doy: 4
  }
};
