// Quechua
import { LocaleData } from 'ngx-bootstrap/chronos';

export const quLocale: LocaleData = {
    months: ['Wata qhata', 'Pichqa', 'Kankak', 'Ayni', 'Kasa', 'Ñawi', 'Sata', 'Paya', 'Santa', 'Willa', 'Puri', 'Chaska'],
    monthsShort: ['Wata', 'Pich', 'Kan', 'Ayn', 'Kas', 'Ñaw', 'Sat', 'Pay', 'San', 'Wil', 'Pur', 'Chas'],
    weekdays: ['Ñawi p’unchay', 'Kullaka', 'Kasi p’unchay', 'Wayka p’unchay', 'Ñawi p’unchay', 'P’unchay', 'Waño p’unchay'],
    weekdaysShort: ['Ñaw', 'Kul', 'Kas', 'Way', 'Ñaw', 'P’u', 'Waño'],
    weekdaysMin: ['Ñ', 'Ku', 'Ka', 'Wa', 'Ñ', 'Pu', 'Wa'],
    longDateFormat: {
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY LT',
        LLLL: 'dddd, D MMMM YYYY LT',
        LT: 'HH:mm',
        LTS: 'HH:mm:ss'
    },
    calendar: {
        sameDay: '[Ñawi p’unchay] LT',
        nextDay: '[Kullaka] LT',
        nextWeek: 'dddd [p’unchay] LT',
        lastDay: '[Wayka p’unchay] LT',
        lastWeek: '[Ñawi] dddd [p’unchay] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: '%s kachkan',
        past: '%s wañuchkan',
        s: 'huk segunduk',
        m: 'huk minuto',
        mm: '%d minuto',
        h: 'huk hora',
        hh: '%d hora',
        d: 'huk killa',
        dd: '%d killa',
        M: 'huk wata',
        MM: '%d wata',
        y: 'huk año',
        yy: '%d año'
    },
    ordinal: (number: number) => `${number}`,
    week: {
        dow: 0, // Sunday is the first day of the week
        doy: 6  // The week that contains Jan 6 is the first week of the year
    }
};
