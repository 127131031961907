<div class="member-dashboard-activities weekly-challenges px-5">
  <div class="row">
    <div class="col-md-12 col-lg-7">
      <app-activities></app-activities>
      <app-final-onboarding></app-final-onboarding>
    </div>
    <div class="col-md-12 col-lg-5 d-none d-lg-flex">
      <div class="boxMain card-white card-white-sm border-radius-md px-0 card-no-space">
        <app-member-tasks></app-member-tasks>
      </div>
    </div>
  </div>
</div>
