<div class="leader-assessment-create" #container>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLongTitle">{{page.addAssessment}}</h5>
    <button #closebutton type="button" class="close" (click)="closeModal()" data-dismiss="modal" [attr.aria-label]="page.close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body addAssmntTap">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="form-group col-md-12 col-lg-8">
          <label for="name">{{page.assessmentName}}<span class="required-asterisk">*</span> </label>
          <input id="name" formControlName="name" maxlength="40" type="text" [placeholder]="page.assessmentName"
            class="form-control" [ngClass]="{'danger-class': f.name.errors && f.name.touched}" />
          <div *ngIf="f.name.errors && f.name.touched" class="invalid-feedback">
            <div *ngIf=" f.name.errors.required">
              {{page.assessmentNameRequired}}
            </div>
          </div>
        </div>
        <div class="form-group col-md-12 col-lg-8">
          <label for="type">{{page.assessmentType}}</label>

          <div *ngIf="!f.uuid.value" class="d-flex radio-group">
            <label class="containerLabel"><span class="radio-label">{{page.self}}</span>
              <input formControlName="type" type="radio" id="self" name="type" value="self" checked="checked">
              <span class="checkmark"></span>
            </label>
            <label *ngIf="!f.group.value || f.group.value?.memberUUIDs.length > 1" class="containerLabel"><span class="radio-label">{{page.peer}}</span>
              <input formControlName="type" type="radio" id="peer" name="type" value="peer">
              <span class="checkmark"></span>
            </label>
            <label *ngIf="!f.group.value || f.group.value.memberUUIDs.length > 1" class="containerLabel"><span class="radio-label">{{page.leaderFeedback}}</span>
              <input formControlName="type" type="radio" id="leader" name="type" value="leader">
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </div>

      <div class="col-12 px-0">
        <label for="type">{{page.assignSelfAssessment}}<span class="required-asterisk">*</span></label>
        <div class="form-group col-12 mt-2">
          <label for="group">{{page.group}} <span class="required-asterisk">*</span></label>
          <select aria-label="school" id="group" formControlName="group" (change)="onGroupChange()" class="form-control"
            [ngClass]="{'danger-class': f.group.errors && f.group.touched}"  [(ngModel)]="groupFilter.value">
            <option [ngValue]="null" disabled>
            {{page.selectGroup}}
            </option>
            <option   *ngFor="let group of groupFilter.options"
            [ngValue]="group"
          >
            {{group.name}}
            </option>
          </select>
          <div *ngIf="f.group.errors && f.group.touched" class="invalid-feedback">
            <div *ngIf="f.group.errors.required">
            {{page.groupRequired}}
            </div>
          </div>
        </div>
        <div class="form-group col-12">
          <div class="row">
            <div class="form-group col-md-12 col-lg-6">
              <label for="startDate">{{page.startDate}} <span class="required-asterisk">*</span></label>
              <input id="startDate" type="text" formControlName="startDate"
                [ngClass]="{'danger-class': f.startDate.errors && f.startDate.touched}" [placeholder]="page.startDate"
                class="form-control" bsDatepicker [bsConfig]="{showWeekNumbers: false}" [minDate]="minStartDate" [maxDate]="maxEndDate" placement ="top" readonly>
              <div *ngIf="f.startDate.errors && f.startDate.touched" class="invalid-feedback">
                <div *ngIf=" f.startDate.errors.required">
                  {{page.startDateRequired}}
                </div>
              </div>
              <div *ngIf="form?.errors?.dateConflict" class="invalid-feedback">
                <div>
                  {{page.startBeforeEnd}}
                </div>
              </div>
              <div *ngIf="form?.errors?.startDate" class="invalid-feedback">
                <div>
                  {{page.startTodayOrLater}}                        
                </div>
              </div>
            </div>
            <div class="form-group col-md-12 col-lg-6">
              <label for="endDate">{{page.endDate}} <span class="required-asterisk">*</span> </label>
              <input id="endDate" formControlName="endDate"
                [ngClass]="{'danger-class': f.endDate.errors && f.endDate.touched}" type="text"[placeholder]="page.endDate"
                class="form-control" bsDatepicker [bsConfig]="{showWeekNumbers: false}" [minDate]="minStartDate" [maxDate]="maxEndDate" placement ="top" readonly>
              <div *ngIf="f.endDate.errors && f.endDate.touched" class="invalid-feedback">
                <div *ngIf="f.endDate.errors.required">
                  {{page.endDateRequired}}                        
                </div>
              </div>
            </div>
          </div>
        </div>
        <div #tabSet class="tab-content tab-sm-none radio-group" id="groupOrIndividualTabs">
          <div class="wrapper tab-pane active">
            <div class="main-container p-0 tab-show">
              <tabset class="nested-tab tab-purple create-assessment-tabs">
                <tab heading="{{page.group}}" #tab2>
                </tab>
                <tab heading="{{page.individuals}}" #tab1>
                  <div class="form-group col-12">
                    <div class="customStepper">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="input-group">
                            <input formControlName="searchUser" (keyup)="searchUser($event)" (blur)="searchUser($event)" type="text" name="search" class="form-control"
                             [placeholder]="page.searchUser" />
                            <span class="fa fa-search"></span>
                          </div>
                        </div>

                      </div>
                      <div class="d-flex justify-content-between align-items-center py-2 w-100">
                        <p>{{page.selectUsers}}</p>
                        <p>
                          <input type="checkbox" #checkAll (click)="selectAllUsers()" value="{{page.selectAll}}">
                          <label for="checkAll">{{page.selectAll}}</label>
                        </p>
                      </div>

                      <div class="userGroups">
                        <div class="row">
                          <div *ngFor="let user of filteredUsers, let i = index" class="col-md-4">
                            <label class="container-chk">
                              {{user.name}}
                              <p class="mb-0">{{user.email}}</p>
                              <input (change)="selectIndividual($event)" [checked]="isSelected(user.email)" type="checkbox" value="{{user.email}}">
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </tab>
              </tabset>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary">{{labels.save}}</button>
        <button type="button" class="cancel mr-3" data-dismiss="modal" [attr.aria-label]="page.close" 
          (click)="closeModal()">{{labels.cancel}}</button>
      </div>
    </form>
  </div>
</div>