// Telugu
import { LocaleData } from 'ngx-bootstrap/chronos';

export const teLocale: LocaleData = {
    months: ['జనవరి', 'ఫిబ్రవరి', 'మార్చి', 'ఏప్రిల్', 'మే', 'జూన్', 'జూలై', 'ఆగస్టు', 'సెప్టెంబర్', 'అక్టోబర్', 'నవంబర్', 'డిసెంబర్'],
    monthsShort: ['జన', 'ఫిబ', 'మార్చి', 'ఏప్రి', 'మే', 'జూన్', 'జూలై', 'ఆగ', 'సెప్టెం', 'అక్టో', 'నవ', 'డిసెం'],
    weekdays: ['ఆదివారం', 'సోమవారం', 'మంగళవారం', 'బుధవారం', 'గురువారం', 'శుక్రవారం', 'శనివారం'],
    weekdaysShort: ['ఆది', 'సోమ', 'మంగళ', 'బుధ', 'గురు', 'శుక్ర', 'శని'],
    weekdaysMin: ['ఆ', 'సో', 'మ', 'బు', 'గు', 'శు', 'శ'],
    longDateFormat: {
        LT: 'A h:mm',
        LTS: 'A h:mm:ss',
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY A h:mm',
        LLLL: 'dddd, D MMMM YYYY A h:mm'
    },
    calendar: {
        sameDay: '[ఈ రోజు] LT',
        nextDay: '[రేపు] LT',
        nextWeek: 'dddd [నాడు] LT',
        lastDay: '[నిన్న] LT',
        lastWeek: '[గత] dddd [నాడు] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: '%s లో',
        past: '%s కింద',
        s: 'కొన్ని నిమిషాలు',
        ss: '%d నిమిషాలు',
        m: 'ఒక నిమిషం',
        mm: '%d నిమిషాలు',
        h: 'ఒక గంట',
        hh: '%d గంటలు',
        d: 'ఒక రోజు',
        dd: '%d రోజులు',
        M: 'ఒక నెల',
        MM: '%d నెలలు',
        y: 'ఒక సంవత్సరం',
        yy: '%d సంవత్సరాలు'
    },
    ordinal: (num: number): string => `${num}వ`,
    week: {
        dow: 0, // Sunday is the first day of the week.
        doy: 6  // The week that contains Jan 6th is the first week of the year.
    }
};
