import { Component, OnInit, Input } from "@angular/core";
import {
  FormGroup,
  Validators,
  FormControl,
  ValidationErrors,
} from "@angular/forms";
import { Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { LanguageService, WalkthroughToolTipService } from "@services/public";
import { MemberOnboardingService } from "@services/member";
import { keyPressOnlyAlphabets, EMAIL_PATTERN } from "@util/common";

@Component({
  selector: "app-member-assessment-external-create-or-update",
  templateUrl: "./create.or.update.component.html",
  styleUrls: ["./create.or.update.component.scss"],
})
export class MemberAssessmentExternalCreateOrUpdateComponent implements OnInit {
  // Expose this function to the template.
  keyPressOnlyAlphabets: typeof keyPressOnlyAlphabets = keyPressOnlyAlphabets;

  @Input() public set assessmentData(data: any) {
    if (data) {
      this._assessmentData = data;
      this.form.patchValue(data);
    }
  }
  public get assessmentData(): any {
    return this._assessmentData;
  }

  form: FormGroup;
  private _assessmentData: any;

  // Language Translation
  pageLanguage: any;
  appLabels: any;
  basePath = `pages.member.assessment`;

  constructor(
    private router: Router,
    private modalSvc: BsModalService,
    private _languageSvc: LanguageService,
    private _walkthroughSvc: WalkthroughToolTipService,
    private _memberOnboardingSvc: MemberOnboardingService
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      uuid: new FormControl(null),
      assessmentName: new FormControl("", [
        Validators.required,
        Validators.maxLength(40),
        this.noWhitespaceValidator1,
      ]),
      firstName: new FormControl("", [
        Validators.required,
        Validators.maxLength(40),
        this.noWhitespaceValidator1,
      ]),
      lastName: new FormControl("", [
        Validators.required,
        Validators.maxLength(40),
        this.noWhitespaceValidator1,
      ]),
      email: new FormControl("", [
        Validators.required,
        Validators.pattern(EMAIL_PATTERN),
        this.noWhitespaceValidator1,
      ]),
      relationship: new FormControl("", [
        Validators.required,
        Validators.maxLength(40),
        this.noWhitespaceValidator1,
      ]),
    });
    const pageLanguagePath = this.basePath+`.external.pages.createOrUpdate`;
    this._languageSvc.get([pageLanguagePath]).then((value) => {
      this.pageLanguage = value[pageLanguagePath];
      this._walkthroughSvc.updateElement();
    });
    this._languageSvc.get([`labels`]).then((value)=> {
      this.appLabels = value[`labels`];
    });
  }

  noWhitespaceValidator1(control: FormControl): ValidationErrors | null {
    const isWhitespace = (control.value || "").trim().length === 0;
    return isWhitespace ? { whitespace: true } : null;
  }

  get f(): any {
    return this.form.controls;
  }

  public onSave() {
    // Validate form.
    this.form.markAllAsTouched();
    if (this.form.invalid) return;
    localStorage.setItem(
      "createExternal",
      JSON.stringify(this.form.value));
    this._walkthroughSvc.emitExternalFormValid({valid: true});
    this.modalSvc.hide();
    this.router.navigate(["member/assessment/external/update-skills"]);
  }

  closeModal() {
    if (this._memberOnboardingSvc.isRunning) {
      return;
    }
    this.modalSvc.hide();
  }
}
