import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-assessment-instruction-popup',
  templateUrl: './assessment-instruction-popup.component.html',
})
export class AssessmentInstructionPopupComponent implements OnInit {
  modalRef?: BsModalRef;
  message?: string;
  @Input() isSelf:any;
  @Input() pageLanguage: any;
  @Input() labels: any;
  isPeer:boolean;

  
  constructor(
    public modalSvc: BsModalService,
  ) { }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalSvc.show(template, {class: 'modal-sm modal-dialog-centered'});
  }
 
  confirm(): void {
    this.message = this.labels.confirmed;
    this.modalRef?.hide();
  }
 
  decline(): void {
    this.message = this.labels.declined;
    this.modalRef?.hide();
  }
  ngOnInit(): void {
  }


}
