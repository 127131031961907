// Malayalam
import { LocaleData } from 'ngx-bootstrap/chronos';

export const mlLocale: LocaleData = {
    months: ['ജനുവരി', 'ഫെബ്രുവരി', 'മാര്‍ച്ച്', 'ഏപ്രില്', 'മേയ്', 'ജൂണ്', 'ജൂലൈ', 'ഓഗസ്റ്റ്', 'സെപ്റ്റംബര്', 'ഒക്ടോബര്', 'നവംബര്', 'ഡിസംബര്'],
    monthsShort: ['ജനു', 'ഫെബ്', 'മാര്‍', 'ഏപ്രി', 'മേയ്', 'ജൂണ്', 'ജൂലൈ', 'ഓഗ', 'സെപ്റ്റ', 'ഒക്ടോ', 'നവം', 'ഡിസം'],
    weekdays: ['ഞായര്', 'തിങ്കള്', 'ചൊവ്വ', 'ബുധന്', 'വ്യാഴം', 'വെള്ളിയാഴ്', 'ശനിയാഴ്'],
    weekdaysShort: ['ഞാ', 'തിങ്ക', 'ചൊ', 'ബുധ', 'വ്യാ', 'വെള', 'ശനി'],
    weekdaysMin: ['ഞാ', 'തിക്', 'ചൊ', 'ബു', 'വ്യാ', 'വെ', 'ശ'],
    longDateFormat: {
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY LT',
        LLLL: 'dddd, D MMMM YYYY LT',
        LT: 'HH:mm',
        LTS: 'HH:mm:ss'
    },
    calendar: {
        sameDay: '[ഇന്ന്] LT',
        nextDay: '[നാളെ] LT',
        nextWeek: 'dddd [സമയം] LT',
        lastDay: '[ഇന്ന്] LT',
        lastWeek: '[കഴിഞ്ഞ] dddd [സമയം] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: '%s കഴിഞ്ഞ്',
        past: '%s മുമ്പ്',
        s: 'ചില സെക്കന്റുകൾ',
        m: 'ഒരു മിനിറ്റ്',
        mm: '%d മിനിറ്റ്',
        h: 'ഒരു മണിക്കൂര്',
        hh: '%d മണിക്കൂര്',
        d: 'ഒരു ദിവസം',
        dd: '%d ദിവസം',
        M: 'ഒരു മാസം',
        MM: '%d മാസം',
        y: 'ഒരു വര്‍ഷം',
        yy: '%d വര്‍ഷം'
    },
    ordinal: (number: number) => `${number}`,
    week: {
        dow: 1,
        doy: 4
    }
};
