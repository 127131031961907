import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, map } from 'rxjs';
import { Group } from './group.service';
import { Skill } from '@services/public';
import { config } from 'environment';

export interface Assessment {
  uuid: string;
  name: string;
  type: AssessmentType;
  status: AssessmentStatus;
  endDate: string;
  startDate?: string;
  group: Group;
}

export enum AssessmentType {
  SELF = 'self',
  PEER = 'peer',
  LEADER = 'leader'
}

export enum AssessmentStatus {
  INACTIVE = 'INACTIVE',
  SCHEDULED = 'SCHEDULED',
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED'
}

export interface ExternalAssessment {
  uuid: string;
  assessmentName: string; // Name of the assessment.
  firstName: string; // Name of the assessor.
  lastName: string; // Name of the assessor.
  email: string;
  relationship: string;
  startDate: string;
  status: AssessmentStatus;
  skills: Array<Skill>;
}

export interface User {
  uuid: string;
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class AssessmentService {

  private _submitSelfEvent: Subject<void> = new Subject<void>();
  private _createExternalEvent$: Subject<void> = new Subject<void>();

  constructor(
    private http: HttpClient
  ) { }

  get submitSelfEvent(): Observable<void> {
    return this._submitSelfEvent.asObservable();
  }

  get createExternalEvent(): Observable<void>{
    return this._createExternalEvent$.asObservable();
  }

  getAssessments(date?: any): Observable<any> {
    return this.http.get<any>(
      `${config.apiBase}member/assessment/list`);
  }

  getExternal(uuid: string): Observable<any> {
    return this.http.get<any>(
      `${config.apiBase}member/assessment/external/view?uuid=${encodeURIComponent(uuid)}`);
  }

  getExternals(): Observable<any> {
    return this.http.get<any>(
      `${config.apiBase}member/assessment/external/list`);
  }

  createExternal(reqBody): Observable<any> {
    return this.http.post<any>(
      `${config.apiBase}member/assessment/external/create`, reqBody)
      .pipe(map(
        (res:any)=>{
          if (res.success) this._createExternalEvent$.next();
          return res;
        }
      ))
  }

  resendExternal(uuid: string): Observable<any> {
    return this.http.post<any>(
      `${config.apiBase}member/assessment/external/resend`, { uuid });
  }

  getExternalAssessmentsCompleted(): Observable<any> {
    return this.http.get<any>(
      `${config.apiBase}member/assessment/external/completed/count`);
  }

  deleteExternal(uuid: string): Observable<any> {
    return this.http.delete<any>(
      `${config.apiBase}member/assessment/external/delete`,{
        body:{"uuid": uuid}
      });
  }

  getPeer(uuid: string): Observable<any> {
    return this.http.get<any>(
      `${config.apiBase}member/assessment/peer/view?uuid=${encodeURIComponent(uuid)}`);
  }

  submitPeer(uuid: string, skillScores: Array<any>): Observable<any> {
    return this.http.post<any>(
      `${config.apiBase}member/assessment/peer/submit`,
      { uuid, skillScores });
  }

  getSelf(uuid: string): Observable<any> {
    return this.http.get<any>(
      `${config.apiBase}member/assessment/self/view?uuid=${encodeURIComponent(uuid)}`);
  }

  submitSelf(uuid: string, skillScores: Array<any>): Observable<any> {
    return this.http.post<any>(
      `${config.apiBase}member/assessment/self/submit`,
      { uuid, skillScores }
    ).pipe(map(
      (res: any) => {
        if (res.success) this._submitSelfEvent.next();
        return res;
      }
    ));
  }

  getAssessmentHistory(){
    return this.http.get<any>(
      `${config.apiBase}researcher/assessment/stats`
    );
  }

  inventorySubmit(reqBody): Observable<any> {
    return this.http.post<any>(
      `${config.apiBase}member/assessment/inventory/submit`,reqBody);
  }

  getLeader(uuid: string): Observable<any> {
    return this.http.get<any>(
      `${config.apiBase}member/assessment/leader/view?uuid=${encodeURIComponent(uuid)}`);
  }

  submitLeader(uuid: string, skillScores: Array<any>): Observable<any> {
    return this.http.post<any>(
      `${config.apiBase}member/assessment/leader/submit`,
      { uuid, skillScores });
  }

}
