// Turkmen
import { LocaleData } from 'ngx-bootstrap/chronos';

export const tkLocale: LocaleData = {
    months: ['Ýanwar', 'Fewral', 'Mart', 'Aprel', 'Maý', 'Iýun', 'Iýul', 'Awgust', 'Sentýabr', 'Oktýabr', 'Noýabr', 'Dekabr'],
    monthsShort: ['Ýan', 'Few', 'Mar', 'Apr', 'Maý', 'Iýu', 'Iýu', 'Awg', 'Sen', 'Okt', 'Noý', 'Dek'],
    weekdays: ['Ýekşenbe', 'Duşenbe', 'Sişenbe', 'Çarşenbe', 'Penşenbe', 'Juma', 'Şenbe'],
    weekdaysShort: ['Ýek', 'Duş', 'Siş', 'Çar', 'Pen', 'Jum', 'Şen'],
    weekdaysMin: ['Ýe', 'Du', 'Si', 'Ça', 'Pe', 'Ju', 'Şe'],
    longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'dddd, D MMMM YYYY HH:mm'
    },
    calendar: {
        sameDay: '[Şu gün] LT',
        nextDay: '[Erteki gün] LT',
        nextWeek: 'dddd [günü] LT',
        lastDay: '[Düýn] LT',
        lastWeek: '[Geçen] dddd [günü] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: '%s içinde',
        past: '%s öň',
        s: 'birneçe sekunt',
        ss: '%d sekunt',
        m: 'bir minut',
        mm: '%d minut',
        h: 'bir sagat',
        hh: '%d sagat',
        d: 'bir gün',
        dd: '%d gün',
        M: 'bir aý',
        MM: '%d aý',
        y: 'bir ýyl',
        yy: '%d ýyl'
    },
    ordinal: (num: number): string => `${num}.`,
    week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4  // The week that contains Jan 4th is the first week of the year.
    }
};
