// Oromo
import { LocaleData } from 'ngx-bootstrap/chronos';

export const omLocale: LocaleData = {
    months: ['Amajjii', 'Guraandhala', 'Bitootessa', 'Ebla', 'Caamsa', 'Waxabajjii', 'Adoolessa', 'Hagayya', 'Fulbaana', 'Onkololeessa', 'Sambata', 'Muddee'],
    monthsShort: ['Ama', 'Gur', 'Bit', 'Ebl', 'Caa', 'Wax', 'Ado', 'Hag', 'Ful', 'Onk', 'Sam', 'Mud'],
    weekdays: ['Dilbata', 'Wiixata', 'Qibxata', 'Roobii', 'Kamiisa', 'Jimaata', 'Sanbata'],
    weekdaysShort: ['Dil', 'Wii', 'Qib', 'Roo', 'Kam', 'Jim', 'San'],
    weekdaysMin: ['Di', 'Wi', 'Qi', 'Ro', 'Ka', 'Ji', 'Sa'],
    longDateFormat: {
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY LT',
        LLLL: 'dddd, D MMMM YYYY LT',
        LT: 'HH:mm',
        LTS: 'HH:mm:ss'
    },
    calendar: {
        sameDay: '[Har’a] LT',
        nextDay: '[Boru] LT',
        nextWeek: 'dddd [etti] LT',
        lastDay: '[Har’a] LT',
        lastWeek: '[Torban] dddd [etti] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: '%s booda',
        past: '%s darbe',
        s: 'sekondii muraasa',
        m: 'daqiiqa tokko',
        mm: '%d daqiiqa',
        h: 'saʼa tokko',
        hh: '%d saʼaa',
        d: 'guyyaa tokko',
        dd: '%d guyyaa',
        M: 'jiʼa tokko',
        MM: '%d jiʼoota',
        y: 'waggaa tokko',
        yy: '%d waggaa'
    },
    ordinal: (number: number) => `${number}`,
    week: {
        dow: 0, // Sunday is the first day of the week
        doy: 6  // The week that contains Jan 6 is the first week of the year
    }
};
