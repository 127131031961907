// Persian
import { LocaleData } from 'ngx-bootstrap/chronos';

export const faLocale: LocaleData = {
    months: ['ژانویه', 'فوریه', 'مارس', 'آوریل', 'مه', 'ژوئن', 'ژوئیه', 'اوت', 'سپتامبر', 'اکتبر', 'نوامبر', 'دسامبر'],
    monthsShort: ['ژان', 'فور', 'مار', 'آور', 'مه', 'ژوئن', 'ژوئیه', 'اوت', 'سپت', 'اکت', 'نوام', 'دسا'],
    weekdays: ['یکشنبه', 'دوشنبه', 'سه‌شنبه', 'چهارشنبه', 'پنج‌شنبه', 'جمعه', 'شنبه'],
    weekdaysShort: ['یک', 'دو', 'سه', 'چهار', 'پنج', 'جمعه', 'ش'],
    weekdaysMin: ['ی', 'د', 'س', 'چ', 'پ', 'ج', 'ش'],
    longDateFormat: {
        L: 'YYYY/MM/DD',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY LT',
        LLLL: 'dddd، D MMMM YYYY LT',
        LT: 'HH:mm',
        LTS: 'HH:mm:ss'
    },
    calendar: {
        sameDay: '[امروز در] LT',
        nextDay: '[فردا در] LT',
        nextWeek: 'dddd [در] LT',
        lastDay: '[دیروز در] LT',
        lastWeek: '[هفته گذشته در] dddd [در] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: '%s دیگر',
        past: '%s پیش',
        s: 'چند ثانیه',
        m: 'یک دقیقه',
        mm: '%d دقیقه',
        h: 'یک ساعت',
        hh: '%d ساعت',
        d: 'یک روز',
        dd: '%d روز',
        M: 'یک ماه',
        MM: '%d ماه',
        y: 'یک سال',
        yy: '%d سال'
    },
    ordinal: (number: number) => `${number}`,
    week: {
        dow: 6, // Saturday is the first day of the week
        doy: 12  // The week that contains Jan 1 is the first week of the year
    }
};
