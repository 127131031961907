import { Component, Input, ViewChild, ElementRef, OnChanges, SimpleChanges} from '@angular/core';
import { CardService } from '@services/member';

export interface InfoCard {
  header?: string;
  body?: any;
  domain?: any;
  type?: any;
}

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss']
})
export class InfoCardComponent implements OnChanges {
  @Input() public header: string;
  @Input() public body: any;
  @Input () public domain: string;
  @Input () public type: string;
  @Input () public showFooter: boolean = false;
  @ViewChild('card') card: ElementRef;

  constructor(private _cardSvc: CardService) { }

  onClose() {
    this._cardSvc.emitCloseEvent(true);
  }

  isHTML(content: string): boolean {
    const htmlPattern = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
    return htmlPattern.test(content);
  }

  ngOnChanges(_changes: SimpleChanges): void {
    if (this.showFooter && this.card) {
      this.card.nativeElement.classList.add('show-footer');
    }
  }

}
