// Irish
import { LocaleData } from 'ngx-bootstrap/chronos';

export const gaLocale: LocaleData = {
    months: ['Eanáir', 'Feabhra', 'Márta', 'Aibreán', 'Bealtaine', 'Meitheamh', 'Iúil', 'Lúnasa', 'Meán Fómhair', 'Deireadh Fómhair', 'Samhain', 'Nollaig'],
    monthsShort: ['Ean', 'Feabh', 'Már', 'Aib', 'Beal', 'Meith', 'Iúil', 'Lún', 'Meán', 'Deir', 'Samh', 'Noll'],
    weekdays: ['Dé Domhnaigh', 'Dé Luain', 'Dé Máirt', 'Dé Céadaoin', 'Déardaoin', 'Dé hAoine', 'Dé Sathairn'],
    weekdaysShort: ['Dom', 'Lua', 'Mái', 'Céa', 'Déa', 'hAo', 'Sat'],
    weekdaysMin: ['Do', 'Lu', 'Má', 'Cé', 'Dé', 'hA', 'Sa'],
    longDateFormat: {
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY LT',
        LLLL: 'dddd, D MMMM YYYY LT',
        LT: 'HH:mm',
        LTS: 'HH:mm:ss'
    },
    calendar: {
        sameDay: '[Inniu ag] LT',
        nextDay: '[Amárach ag] LT',
        nextWeek: 'dddd [ag] LT',
        lastDay: '[Inné ag] LT',
        lastWeek: '[Ar an] dddd [seo caite ag] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: 'i %s',
        past: '%s ó shin',
        s: 'cúpla soicind',
        m: 'nóiméad',
        mm: '%d nóiméad',
        h: 'uair an chloig',
        hh: '%d uair an chloig',
        d: 'lá',
        dd: '%d lá',
        M: 'mí',
        MM: '%d míonna',
        y: 'bliain',
        yy: '%d bliain'
    },
    ordinal: (number: number) => `${number}`,
    week: {
        dow: 1,
        doy: 4
    }
};
