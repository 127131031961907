<div class="tabHeadMain px-4">
  <div class="container">
    <div class="tabHead">
      <span routerLink="/member/assessment/external/list" class="back-arrow">
        <i class="fas fa-arrow-left"></i>
      </span> {{pageLanguage?.createOrUpdate.modalTitle}}
    </div>
  </div>
</div>

<div class="container-fluid pb-xl px-4">
  <div class="pageTopCard">
    <div class="topCardLbl">
      <h5>{{pageLanguage?.createOrUpdate.page.title}} </h5>
      <span class="btn-c">
        <button type="button" (click)="editExternal()" class="btn btn-primary">
          <i class="fas fa-edit"></i> {{appLabels?.edit}}
        </button>
      </span>
    </div>

    <div class="topCardCntr">
      <div class="row">
        <div class="col-xs-12 col-sm-4 col-md-3 topCardCol">
          <label>{{appLabels?.assessmentName}}</label>
          <span>{{assessment.assessmentName}}</span>
        </div>
        <div class="col-xs-12 col-sm-4 col-md-3 topCardCol">
          <label>{{appLabels?.name}}</label>
          <span>{{assessment.firstName}} {{assessment.lastName}}</span>
        </div>
        <div class="col-xs-12 col-sm-4 col-md-3 topCardCol">
          <label>{{appLabels?.email}}</label>
          <span>{{assessment.email}}</span>
        </div>
        <div class="col-xs-12 col-sm-4 col-md-3 topCardCol">
          <label>{{pageLanguage?.createOrUpdate.relationship}}</label>
          <span>{{assessment.relationship}}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="cardDsplMain">
    <ng-container *ngFor="let domain of domains; let i = index;">
      <div class="cardDsplRow">
        <div class="cardDsplLft" data-toggle="collapse"
          [attr.href]="'#collapse' + i" role="button"
          aria-expanded="false" [attr.aria-controls]="'collapse' + i">
          <div class="cardDsplLftInner">
            {{domain.name}}
          </div>
        </div>
        <div class="cardDsplRyt collapse" [id]="'collapse' + i">
          <ul>
            <ng-container *ngFor="let skill of domain.skills">
              <li (click)="toggleSkill(skill)">
                <div class="cardDsplRytItems cursor-pointer {{ assessment.skills.includes(skill) ? 'selected' : '' }}">
                  <span>{{skill.name}}</span>
                </div>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="timesheetMain">
    <div class="row">
      <div class="col-xs-12 col-sm-4 col-md-4 timesheetColMain">
        <div class="timesheetCol">
          <h5>{{pageLanguage?.updateSkills.competenciesSelected}}</h5>
          <p>{{assessment.skills.length}}</p>
        </div>
      </div>
      <div class="col-xs-12 col-sm-4 col-md-4 timesheetColMain text-left">
        <div class="timesheetCol">
          <h5># {{pageLanguage?.updateSkills.numberOfQuestions}}</h5>
          <p>{{assessment.skills.length}}</p>
        </div>
      </div>
      <div class="col-xs-12 col-sm-4 col-md-4 timesheetColMain">
        <div class="timesheetCol">
          <h5>{{pageLanguage?.updateSkills.estimatedCompletionTime}}</h5>
          <p>{{assessment.skills.length}}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="footer-inner-action timesheetBtns-c btn-c">
    <button type="button" routerLink="/member/dashboard" class="btn btn-md">{{appLabels?.cancel}}</button>
    <button [disabled]="!assessment.skills.length" (click)="send()"
      type="button" class="btn btn-primary">{{appLabels?.send}}</button>
  </div>
</div>
