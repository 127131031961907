import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

export type LogoutFunction = () => (void | Promise<void>);

@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  private _authLogout: Function = () => {};
  private _preLogoutFunctions: Map<symbol, LogoutFunction> = (
    new Map<symbol, LogoutFunction>()
  );
  private _postLogoutFunctions: Map<symbol, LogoutFunction> = (
    new Map<symbol, LogoutFunction>()
  );
  private _logoutFunctions: Array<Function> = [];

  constructor(
    private _router: Router
  ) { }

  set authLogout(authLogout: Function) {
    this._authLogout = authLogout;
  }

  /**
   * Adds and removes functions to the before logout queue.
   * @param {symbol | LogoutFunction} arg1
   *  The function key or the function to call.
   * @param {LogoutFunction | undefined} func The function to call. Optional.
   */
  // Adds a permanent function.
  callBeforeLogout(func: LogoutFunction): void;
  // Removes a temporary function.
  callBeforeLogout(key: symbol): void;
  // Adds a temporary function.
  callBeforeLogout(key: symbol, func: LogoutFunction): void;
  callBeforeLogout(arg1: symbol | LogoutFunction, func?: LogoutFunction): void {
    let key: symbol = Symbol();
    if (typeof arg1 === 'symbol') key = arg1;
    else func = arg1;
    if (!func) this._preLogoutFunctions.delete(key);
    else this._preLogoutFunctions.set(key, func);
  }

  /**
   * Adds and removes functions to the after logout queue.
   * @param {symbol | LogoutFunction} arg1
   *  The function key or the function to call.
   * @param {LogoutFunction | undefined} func The function to call. Optional.
   */
  // Adds a permanent function.
  callAfterLogout(func: LogoutFunction): void;
  // Removes a temporary function.
  callAfterLogout(key: symbol): void;
  // Adds a temporary function.
  callAfterLogout(key: symbol, func: LogoutFunction): void;
  callAfterLogout(arg1: symbol | LogoutFunction, func?: LogoutFunction) {
    let key: symbol = Symbol();
    if (typeof arg1 === 'symbol') key = arg1;
    else func = arg1;
    if (!func) this._postLogoutFunctions.delete(key);
    else this._postLogoutFunctions.set(key, func);
  }

  subscribe(func: Function): void {
    this._logoutFunctions.push(func);
  }

  async logout(): Promise<void> {
    for (const entries of this._preLogoutFunctions) await entries[1]();
    this._authLogout();
    for (const entries of this._postLogoutFunctions) await entries[1]();
    this._logoutFunctions.forEach(func => func());
    this._router.navigate(['/']);
  }

}
