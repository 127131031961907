<div class="backdrop"></div>
<div #carouselWrapper (touchstart)="posIni = $event.changedTouches[0].pageX"
  (touchend)="move($event.changedTouches[0].pageX)" 
  class="carousel-container hide animate__animated 
  {{isCarouselVisible? enterAnimationClass: exitAnimationClass}}"
 >
  <ngb-carousel #carousel class="challenge-carousel" 
    [interval]="0" (slide)="onSlide($event)">
    <ng-container *ngFor="let slide of slides; let index = index">
      <ng-template [ngIf]="slide.type === 'video'">
        <ng-template ngbSlide [id]="slide.type">
          <app-video-card [currentIndex]="index" [slide]="slide" [slides]="slides"></app-video-card>
        </ng-template>
      </ng-template>
      <ng-template [ngIf]="slide.type !== 'video'">
        <ng-template ngbSlide>
          <app-info-card *ngIf="carouselInfo?.cardType == 'reading' || slide.type === 'reading'" 
            [type]="carouselInfo.readingType" [domain]="carouselInfo.domain"
            [header]="slide.header" [body]="slide.body" [showFooter]="last_slide">
          </app-info-card>
          <app-checklist-card *ngIf="slide.type == 'checklist'" [slide]="slide"></app-checklist-card>
          <app-view-card *ngIf="slide.type == 'userTurn' || slide.type == 'completion'" [slide]="slide"></app-view-card>
        </ng-template>
      </ng-template>
    </ng-container>
  </ngb-carousel>
</div>
