// Fijian
import { LocaleData } from 'ngx-bootstrap/chronos';

export const fjLocale: LocaleData = {
    months: ['Janueri', 'Veverueri', 'Maji', 'Epereli', 'Mei', 'June', 'Julai', 'Okosita', 'Seviteba', 'Okotova', 'Noveba', 'Tiseba'],
    monthsShort: ['Jan', 'Vev', 'Maj', 'Epe', 'Mei', 'Jun', 'Jul', 'Oko', 'Sev', 'Oko', 'Nov', 'Tis'],
    weekdays: ['Siga Tabu', 'Moniti', 'Tisidei', 'Vukelulu', 'Lotulevu', 'Vakaraubuka', 'Vakarauwai'],
    weekdaysShort: ['Tab', 'Mon', 'Tis', 'Vuk', 'Lot', 'Vak', 'Vwk'],
    weekdaysMin: ['Ta', 'Mo', 'Ti', 'Vu', 'Lo', 'Va', 'Vw'],
    longDateFormat: {
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY LT',
        LLLL: 'dddd, D MMMM YYYY LT',
        LT: 'HH:mm',
        LTS: 'HH:mm:ss'
    },
    calendar: {
        sameDay: '[Nikua ena] LT',
        nextDay: '[Ni mataka ena] LT',
        nextWeek: 'dddd [ena] LT',
        lastDay: '[Ena noa ena] LT',
        lastWeek: '[Ena] dddd [sa oti ena] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: 'ena %s',
        past: '%s sa oti',
        s: 'vica na sekodi',
        m: 'dua na miniti',
        mm: '%d na miniti',
        h: 'dua na aua',
        hh: '%d na aua',
        d: 'dua na siga',
        dd: '%d na siga',
        M: 'dua na vula',
        MM: '%d na vula',
        y: 'dua na yabaki',
        yy: '%d na yabaki'
    },
    ordinal: (number: number) => `${number}`,
    week: {
        dow: 1,
        doy: 4
    }
};
