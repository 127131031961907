// Nyanja (Chichewa)
import { LocaleData } from 'ngx-bootstrap/chronos';

export const nyLocale: LocaleData = {
    months: ['Januware', 'Februware', 'Machi', 'Epulo', 'Meyi', 'Juni', 'Julai', 'Ogasiti', 'Seputembala', 'Oktobala', 'Novembala', 'Disembala'],
    monthsShort: ['Jan', 'Feb', 'Mac', 'Epu', 'Mey', 'Jun', 'Jul', 'Oga', 'Sep', 'Okt', 'Nov', 'Dis'],
    weekdays: ['Sontag', 'Lumachindo', 'Lachitatu', 'Lachinayi', 'Lachisanu', 'Lachisanu', 'Mawa'],
    weekdaysShort: ['Son', 'Lum', 'Lac', 'Lac', 'Lac', 'Lac', 'Maw'],
    weekdaysMin: ['So', 'Lu', 'La', 'La', 'La', 'La', 'Ma'],
    longDateFormat: {
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY LT',
        LLLL: 'dddd, D MMMM YYYY LT',
        LT: 'HH:mm',
        LTS: 'HH:mm:ss'
    },
    calendar: {
        sameDay: '[Lero] LT',
        nextDay: '[Mawa] LT',
        nextWeek: 'dddd [pa] LT',
        lastDay: '[Kale] LT',
        lastWeek: '[Sabata] dddd [pa] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: 'mu %s',
        past: '%s yapitadi',
        s: 'masikisi angapo',
        m: 'miniti imodzi',
        mm: '%d miniti',
        h: 'ora limodzi',
        hh: '%d maola',
        d: 'tsiku limodzi',
        dd: '%d masiku',
        M: 'mwezi umodzi',
        MM: '%d mwezi',
        y: 'chaka chimodzi',
        yy: '%d chaka'
    },
    ordinal: (number: number) => `${number}`,
    week: {
        dow: 0, // Sunday is the first day of the week
        doy: 6  // The week that contains Jan 6 is the first week of the year
    }
};
