// Pashto
import { LocaleData } from 'ngx-bootstrap/chronos';

export const psLocale: LocaleData = {
    months: ['جنوري', 'فبروري', 'مارچ', 'اپریل', 'مئ', 'جون', 'جولائی', 'اگست', 'ستمبر', 'اکتوبر', 'نومبر', 'دسمبر'],
    monthsShort: ['جن', 'فبر', 'مارچ', 'اپر', 'مئ', 'جون', 'جول', 'اگس', 'ستم', 'اکتو', 'نوم', 'دسم'],
    weekdays: ['اتوار', 'پير', 'منگل', 'بدھ', 'جمعرات', 'جمعہ', 'ہفتہ'],
    weekdaysShort: ['اتوار', 'پير', 'منگل', 'بدھ', 'جمعرات', 'جمعہ', 'ہفتہ'],
    weekdaysMin: ['ات', 'پ', 'م', 'ب', 'ج', 'ج', 'ہ'],
    longDateFormat: {
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY LT',
        LLLL: 'dddd, D MMMM YYYY LT',
        LT: 'HH:mm',
        LTS: 'HH:mm:ss'
    },
    calendar: {
        sameDay: '[نن ورځ] LT',
        nextDay: '[سبا] LT',
        nextWeek: 'dddd [په] LT',
        lastDay: '[کاله] LT',
        lastWeek: '[تېر] dddd [په] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: 'په %s',
        past: '%s وړاندې',
        s: 'څو ثانیې',
        m: 'یو دقیقه',
        mm: '%d دقیقې',
        h: 'یو ساعت',
        hh: '%d ساعته',
        d: 'یو ورځ',
        dd: '%d ورځې',
        M: 'یو میاشت',
        MM: '%d میاشتې',
        y: 'یو کال',
        yy: '%d کاله'
    },
    ordinal: (number: number) => `${number}`,
    week: {
        dow: 0, // Sunday is the first day of the week
        doy: 6  // The week that contains Jan 6 is the first week of the year
    }
};
