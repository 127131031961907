<nav mat-tab-nav-bar class="app-tabs">
  <a mat-tab-link
    *ngFor="let tab of tabs"
    class="{{ tab === selectedTab ? 'selected' : '' }}"
    [id]="tab.id"
    [routerLink]="tab.link"
  >
    {{ tab.display }}
  </a>
</nav>
