// Marathi
import { LocaleData } from 'ngx-bootstrap/chronos';

export const mrLocale: LocaleData = {
    months: ['जानेवारी', 'फेब्रुवारी', 'मार्च', 'एप्रिल', 'मे', 'जून', 'जुलै', 'ऑगस्ट', 'सप्टेंबर', 'ऑक्टोबर', 'नोव्हेंबर', 'डिसेंबर'],
    monthsShort: ['जान', 'फेब', 'मार्च', 'एप्रि', 'मे', 'जून', 'जुलै', 'ऑग', 'सप्ट', 'ऑक्टो', 'नोव्हे', 'डिस'],
    weekdays: ['रविवारी', 'सोमवारी', 'मंगळवारी', 'बुधवारी', 'गुरुवारी', 'शुक्रवारी', 'शनिवारी'],
    weekdaysShort: ['रवी', 'सोम', 'मंग', 'बुध', 'गुरु', 'शुक्र', 'शनि'],
    weekdaysMin: ['र', 'सो', 'मं', 'बु', 'गु', 'शु', 'श'],
    longDateFormat: {
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY LT',
        LLLL: 'dddd, D MMMM YYYY LT',
        LT: 'HH:mm',
        LTS: 'HH:mm:ss'
    },
    calendar: {
        sameDay: '[आज] LT',
        nextDay: '[उद्याचा] LT',
        nextWeek: 'dddd [साठी] LT',
        lastDay: '[काल] LT',
        lastWeek: '[गेल्या] dddd [साठी] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: '%s मध्ये',
        past: '%s पूर्वी',
        s: 'काही सेकंद',
        m: 'एक मिनिट',
        mm: '%d मिनिटे',
        h: 'एक तास',
        hh: '%d तास',
        d: 'एक दिवस',
        dd: '%d दिवस',
        M: 'एक महिना',
        MM: '%d महिने',
        y: 'एक वर्ष',
        yy: '%d वर्षे'
    },
    ordinal: (number: number) => `${number}`,
    week: {
        dow: 0,
        doy: 6
    }
};
