// Lingala
import { LocaleData } from 'ngx-bootstrap/chronos';

export const lnLocale: LocaleData = {
    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jui', 'Jui', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc'],
    weekdays: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    weekdaysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    weekdaysMin: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
    longDateFormat: {
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY LT',
        LLLL: 'dddd, D MMMM YYYY LT',
        LT: 'HH:mm',
        LTS: 'HH:mm:ss'
    },
    calendar: {
        sameDay: '[Aujourd’hui] LT',
        nextDay: '[Demain] LT',
        nextWeek: 'dddd [prochain] LT',
        lastDay: '[Hier] LT',
        lastWeek: '[Dernier] dddd [à] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: 'dans %s',
        past: '%s passé',
        s: 'quelques secondes',
        m: 'une minute',
        mm: '%d minutes',
        h: 'une heure',
        hh: '%d heures',
        d: 'un jour',
        dd: '%d jours',
        M: 'un mois',
        MM: '%d mois',
        y: 'un an',
        yy: '%d ans'
    },
    ordinal: (number: number) => `${number}`,
    week: {
        dow: 1,
        doy: 4
    }
};
