<div class="d-flex justify-content-center plain-rounded-card pt-5 pt-md-4 p-4">
  <div class="card card-primary col-sm-12 col-md-8 col-lg-6 px-0">
    <div class="credHeader mb-0 border-rounded-top">
      <img src="assets/img/logo.svg" alt="" />
    </div>
    <div class="card-body scroll-body px-0 py-5 py-1">
      <div id="language-wrap">
        <h3 class="title-main px-4">{{pageLanguage?.title}}</h3>
        <app-language-select></app-language-select>
      </div>
      <form [formGroup]="form" (ngSubmit)="register()">
        <div class="formInner px-4">
          <div class="col-12">
            <div class="row">
              <div class="col-sm-12 col-lg-6 pl-sm-0 mb-3 mb-md-0 px-0 px-md-2">
                <label>{{labels?.firstName}}</label>
                <div class="input-group">
                  <input formControlName="firstName" (keypress)="keyPressNoHTML($event)" type="text"
                    class="form-control" placeholder="{{labels?.firstName}}" [ngClass]="{
                      'danger-class': f.firstName.errors && f.firstName.touched
                    }" />
                  <div *ngIf="f.firstName.errors && f.firstName.touched" class="invalid-feedback">
                    <div *ngIf="f.firstName.errors.required">
                     {{labels?.firstNameRequired}}
                    </div>
                    <div *ngIf="
                        !f.firstName.errors.required &&
                        f.firstName.errors.maxlength?.actualLength > 40
                      ">
                     {{labels?.firstName}} {{labels?.nameLength}}
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-lg-6 pl-sm-0 px-0">
                <label>{{labels?.lastName}}</label>
                <div class="input-group">
                  <input formControlName="lastName" (keypress)="keyPressNoHTML($event)" type="text"
                    class="form-control" placeholder="{{labels.lastName}}" [ngClass]="{
                      'danger-class': f.lastName.errors && f.lastName.touched
                    }" />
                  <div *ngIf="f.lastName.errors && f.lastName.touched" class="invalid-feedback">
                    <div *ngIf="f.lastName.errors.required">
                      {{labels?.lastNameRequired}}
                    </div>
                    <div *ngIf="
                        !f.lastName.errors.required &&
                        f.lastName.errors.maxlength?.actualLength > 40
                      ">
                      {{labels?.lastName}} {{labels?.nameLength}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex-column formInnerCol mt-3">
            <label>{{labels?.email}}</label>
            <div class="input-group">
              <span class="credIcons">
                <input formControlName="email" type="email" maxlength="320" name="email" class="form-control"
                  aria-describedby="emailHelp" [ngClass]="{
                    'danger-class': f.email.errors && f.email.touched
                  }" placeholder="{{labels?.emailAddress}}" />
                <button disabled type="button">
                  <i class="fas fa-user"></i>
                </button>
              </span>
              <div *ngIf="f.email.errors && f.email.touched" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">{{labels.emailRequired}}</div>
                <div *ngIf="f.email.errors.pattern">{{labels?.emailInvalid}}</div>
              </div>
            </div>
          </div>

          <div class="flex-column formInnerCol">
            <label>{{pageLanguage?.organizationCode}}</label>
            <div class="input-group">
              <input
                formControlName="organizationCode" type="text" maxlength="32"
                class="form-control" [ngClass]="{
                  'danger-class':
                    f.organizationCode.errors && f.organizationCode.touched
                }" placeholder="{{pageLanguage?.organizationCode}}" />
              <div
                *ngIf="f.organizationCode.errors && f.organizationCode.touched"
                class="invalid-feedback">
                <div *ngIf="f.organizationCode.errors.required">
                  {{pageLanguage?.organizationCodeRequired}}
                </div>
              </div>
            </div>
          </div>

          <div class="formInnerCol">
            <div class="d-flex">
              <label>{{labels?.password}}</label>
              <!-- <span type="button" [ngbTooltip]="popTemplate" triggers="click" tooltipClass="my-custom-class"
                placement="bottom" class="px-2">
                <span class="info-circle">
                  <img src="assets/img/info-ico-card.svg" alt="info" />
                </span>
              </span> -->
            </div>

            <div class="input-group">
              <span class="credIcons">
                <input formControlName="password" type="{{ hidePassword ? 'password' : 'text' }}" maxlength="32"
                  [ngClass]="{
                    'danger-class': f.password.errors && f.password.touched
                  }" name="password" class="form-control" placeholder="{{labels?.password}}" />
                <button type="button" (click)="hidePassword = !hidePassword">
                  <i class="fas {{
                      hidePassword ? 'fa-eye-slash' : 'fa-eye'
                    }}"></i>
                </button>
              </span>
              <div *ngIf="f.password.errors && f.password.touched" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">
                 {{labels?.passwordRequired}}
                </div>
                <div *ngIf="f.password.errors.pattern">
                  {{pageLanguage?.minimumPasswordNotMet}}
                </div>
              </div>
            </div>
            <br />
            <ul class="list-unstyled text-left">
              <li><strong>{{pageLanguage?.passwordRequirements}}</strong></li>
              <ul class="text-left">
                <li>{{pageLanguage?.passwordLengthRequired}}</li>
                <li>{{pageLanguage?.lowerCaseRequired}}</li>
                <li>{{pageLanguage?.upperCaseRequired}}</li>
                <li>{{pageLanguage?.numberRequired}}</li>
                <li>{{pageLanguage?.specialCharacterRequired}}</li>
              </ul>
            </ul>
          </div>

          <div class="flex-column formInnerCol">
            <label class="mb-1">{{pageLanguage?.confirmPassword}}</label>
            <div class="input-group">
              <span class="credIcons">
                <input formControlName="confirmPassword" type="{{ hideConfirmPassword ? 'password' : 'text' }}"
                  maxlength="32" [ngClass]="{
                    'danger-class':
                      f.confirmPassword.errors && f.confirmPassword.touched
                  }" class="form-control" placeholder="{{this.pageLanguage?.confirmPassword}}" />
                <button type="button" (click)="hideConfirmPassword = !hideConfirmPassword">
                  <i class="fas {{
                      hideConfirmPassword ? 'fa-eye-slash' : 'fa-eye'
                    }}"></i>
                </button>
              </span>
              <div *ngIf="f.confirmPassword.errors && f.confirmPassword.touched" class="invalid-feedback">
                <div *ngIf="f.confirmPassword.errors.required">
                 {{pageLanguage?.confirmPasswordRequired}}
                </div>
              </div>
            </div>
            <div class="flex-column formInnerCol">
              <div *ngIf="!!f.confirmPassword.value && f.password.value !== f.confirmPassword.value">
                <small class="text-danger">{{pageLanguage?.passwordMatchError}}</small>
              </div>
            </div>
          </div>
          <div class="col-12 mb-3 c-form">
            <div class="form-group pt-3">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="gridCheck" formControlName="check" />
                <label class="form-check-label pt-1 d-inline" for="gridCheck">
                  <div [innerHTML]="pageLanguage?.privacyPolicy"></div>
                </label>

              </div>
              <div *ngIf="f.check.errors && f.check.touched" class="invalid-feedback">
                <div *ngIf="f.check.errors.required">
                  {{pageLanguage?.acceptTerms}}</div>
              </div>
            </div>
          </div>
          <div class="col-12 mb-3 c-form">
            <div class="pt-3">
              <ngx-recaptcha2 #captchaElem [siteKey]="'6LcNR9EjAAAAAK5AtgmhoRwwDJesmaCVQj5Kn7Wt'"
                (reset)="handleReset()" (success)="handleSuccess($event)" formControlName="recaptcha">
              </ngx-recaptcha2>
              <div *ngIf="f.recaptcha.errors && f.recaptcha.touched" class="invalid-feedback">
                <div *ngIf="f.recaptcha.errors.required"> {{pageLanguage?.fieldRequired}}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="registerBtn border-top btn-c d-flex align-items-center justify-content-center">
          <app-button [type]="'submit'" class="mt-4"> {{pageLanguage?.title}} </app-button>
        </div>
      </form>

      <div class="login-dont-have-text">
        <span>{{pageLanguage?.alreadyHaveAccount}}
          <a routerLink="/login" routerLinkActive="active" class="link">{{labels?.login}}</a></span>
      </div>
    </div>
  </div>
</div>

<ng-template #popTemplate>
  <ul class="list-unstyled text-left">
    <li><strong>{{pageLanguage?.passwordRequirements}}</strong></li>
    <ul class="text-left">
      <li>{{pageLanguage?.passwordLengthRequired}}</li>
      <li>{{pageLanguage?.lowerCaseRequired}}</li>
      <li>{{pageLanguage?.upperCaseRequired}}</li>
      <li>{{pageLanguage?.numberRequired}}</li>
      <li>{{pageLanguage?.specialCharacterRequired}}</li>
    </ul>
  </ul>
</ng-template>