import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  Domain2Service,
  LanguageService,
  MAIN_KEYS,
  MAIN_KEY_PREFIX,
  Skill2,
  StorageService,
  VOLATILE_KEYS
} from '@services/public';
import { GoalData } from '../common';
import { ReplaySubject, Subscription } from 'rxjs';
import {
  SelectListCustom,
  SelectListNext,
  SelectListOption
} from '@components';
import { APIErrors, ExposedPromise, isAPIErrors } from '@util';
import { GoalServiceCreatePlanResponse, GoalService } from '@services/member';
import { Router } from '@angular/router';

const PAGE_PATH: string = 'skillBuilders.goal.pages.action';

@Component({
  selector: 'app-member-skill-builder-goal-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss']
})
export class MemberSkillBuilderGoalActionComponent
  implements OnInit, OnDestroy
{

  @Input() data: GoalData;
  options: ReplaySubject<Array<SelectListOption>> =
    new ReplaySubject<Array<SelectListOption>>(1);
  defaultOptions: Array<SelectListOption> = [];
  customOptions: Array<SelectListOption> = [];
  custom: SelectListCustom = {
    add: this.addCustom.bind(this),
    delete: this.deleteCustom.bind(this)
  }
  next: SelectListNext = {
    display: '',
    onClick: this.nextOnClick.bind(this)
  }
  defaultLoaded: ExposedPromise<void> = new ExposedPromise<void>();
  private _subscriptions: Subscription = new Subscription();

  // Page langauge.
  page: {[key: string]: string} = {
    title: '',
    subTitle: '',
    submit: ''
  }

  constructor(
    private _domainSvc: Domain2Service,
    private _goalSvc: GoalService,
    private _languageSvc: LanguageService,
    private _router: Router,
    private _storageSvc: StorageService
  ) { }

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        for (const key in this.page) {
          switch (key) {
            case 'title': {
              this._languageSvc.template(
                this.page[key],
                { skill: this._domainSvc.getSkill(this.data.skillUID).name }
              ).then(value => this.page[key] = value);
              break;
            }
            case 'submit': {
              this._languageSvc.template(this.page[key]).then(
                value => {
                  this.page[key] = value;
                  this.next.display = value;
                }
              );
              break;
            }
            default: {
              this._languageSvc.template(this.page[key]).then(
                value => this.page[key] = value);
            }
          }
        }
      });

    // Get options list.
    const skill: Skill2 = this._domainSvc.getSkill(this.data.skillUID);
    const path: string =
      `domains2.${skill.domain.uid}.skills.${skill.uid}.goalActions`;
    this._languageSvc.get([path]).then(
      value => {
        if (
          typeof value[path] === 'object' &&
          value[path] !== null
        ) this.defaultOptions = <Array<SelectListOption>>Object.values(
          value[path]
        ).filter((option: SelectListOption) => !option.value?.skillBuilderType);
        this.defaultLoaded.resolve();
      });
    this._subscriptions.add(this._storageSvc.updates.subscribe(update => {
      if (update.key === MAIN_KEY_PREFIX + MAIN_KEYS.ALL) {
        this.customOptions = [];
        if (Array.isArray(update.value[MAIN_KEYS.GOAL_ACTIONS])) {
          for (const option of update.value[MAIN_KEYS.GOAL_ACTIONS]) {
            if (typeof option !== 'string') continue;
            this.customOptions.push({ display: option, custom: true });
          }
        }
        this._mergeAndSelectOptions();
      }
      if (update.key === MAIN_KEY_PREFIX + MAIN_KEYS.CLEAR) {
        this.customOptions = [];
        this._mergeAndSelectOptions();
      }
      if (update.key === MAIN_KEY_PREFIX + MAIN_KEYS.GOAL_ACTIONS) {
        this.customOptions = [];
        if (Array.isArray(update.value)) {
          for (const option of update.value) {
            if (typeof option !== 'string') continue;
            this.customOptions.push({ display: option, custom: true });
          }
        }
        this._mergeAndSelectOptions();
      }
    }));
    let options: Array<string> = this._storageSvc.getMainStorage(
      MAIN_KEYS.GOAL_ACTIONS);
    if (Array.isArray(options)) {
      for (const option of options) {
        if (typeof option !== 'string') continue;
        this.customOptions.push({ display: option, custom: true });
      }
    }

    this._mergeAndSelectOptions();
  }

  private async _mergeAndSelectOptions(): Promise<void> {
    await this.defaultLoaded;
    this.options.next([...this.customOptions, ...this.defaultOptions].map(
      (option: SelectListOption) => {
        if (option.display === this.data.action)
          option.selected = true;
        return option;
      }));
  }

  addCustom(customOption: string): void {
    if (this.customOptions.findIndex(
      option => option.display === customOption) !== -1) return;
    if (this.defaultOptions.findIndex(
      option => option.display === customOption) !== -1) return;
    this.customOptions.push({ display: customOption, custom: true });
    this._storageSvc.setMainStorage(MAIN_KEYS.GOAL_ACTIONS,
      this.customOptions.map(option => option.display));
  }

  deleteCustom(option: SelectListOption): void {
    this.customOptions = this.customOptions.filter(
      customOption => customOption.display !== option.display);
    if (this.customOptions.length === 0)
      this._storageSvc.setMainStorage(MAIN_KEYS.GOAL_ACTIONS, null);
    else this._storageSvc.setMainStorage(MAIN_KEYS.GOAL_ACTIONS,
        this.customOptions.map(option => option.display));
  }

  nextOnClick(option: SelectListOption): void {
    this.data.action = option.display;
    this.data.skillBuilderType = option.value?.skillBuilderType ?? null;
    this._goalSvc.createPlan(this.data).subscribe(
      (res: GoalServiceCreatePlanResponse | APIErrors) => {
        if (!isAPIErrors(res)) {
          if (!option.value?.skillBuilderType) {
            this._router.navigate(['member/dashboard']);
            return;
          }

          this._storageSvc.setVolatileStorage(
            VOLATILE_KEYS.GOAL_PLAN_UUID, res.goalPlanUUID);
          this._router.navigate(
            ['member/skill-builder', option.value.skillBuilderType]
          );
        }
      }
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

}
