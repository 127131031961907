import { Component, OnInit, Input, AfterContentInit, AfterViewInit, ViewChild} from '@angular/core';
import { VideoViewerComponent } from '@pages/member/video-viewer/video-viewer.component';
import { CardService, CarouselService} from '@services/member';
import { LanguageService } from '@services/public';

const PAGE_PATH: string = 'pages.member.walkthrough.page';

interface slide {
  type: string;
  src: string;
  title?: string;
  subTitle?: string;
  message?: string;
  buttonLabel?: string;
  buttonType?: string;
}

@Component({
  selector: 'app-video-card',
  templateUrl: './video-card.component.html',
  styleUrls: ['./video-card.component.scss']
})
export class VideoCardComponent implements OnInit, AfterContentInit, AfterViewInit {
  @Input() slide: slide;
  @Input() slides: Array<slide>;
  @Input() currentIndex: number; //Current index of the slide
  slideEvent:any; //An object of slide event properties
  @ViewChild(VideoViewerComponent) videoPlayer: VideoViewerComponent;
  page: {[key: string]: string} = {
    buttonLabelContinue: '',
    buttonLabelNext: '',
    videoAssessmentTitle: '',
    videoExternalTitle: '',
    videoGrowthTitle: '',
    videoMessage: '',
    videoSkillsTitle: '',
    videoWelcomeSubTitle: '',
    videoWelcomeTitle: '',
  }
  onSlide: any; //Subscribe to slide events
  constructor(
    private _carouselSvc:CarouselService,
    private _languageSvc:LanguageService,
    private _cardService: CardService,
    ) {}

  ngOnInit(): void {
    this._languageSvc.get([PAGE_PATH]).then((value) => {
      if (
        typeof value[PAGE_PATH] !== 'object' ||
        value[PAGE_PATH] === null
      ) return;
      this.page = value[PAGE_PATH];
      for (const key in this.page)
        this._languageSvc.template(this.page[key]).then(
          value => this.page[key] = value);
      this._cardService.setVideoCardLanguage(this.page);
    });
  }

  ngAfterViewInit(): void {
    const currentIndex = this._carouselSvc.getCurrentIndex();
    if(this.slides[currentIndex].type === 'video')
     this.videoPlayer.playVideo();
  }

  ngAfterContentInit(): void {
    this.onSlide = this._carouselSvc
    .onSlideEvent$.subscribe((value)=>{
      this.slideEvent = value;
      setTimeout(() => {
        if (this.slideEvent.current === 'video'){
          this.videoPlayer.playVideo();
        }
      }, 100);
    });
  }

  onClose() {
      this.videoPlayer.closeVideoPlayer();
    this._cardService.emitCloseEvent(true);
  }

  onNext() {
    this._cardService.emitNextCardEvent(true);
  }

}
