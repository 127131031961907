// Bengali
import { LocaleData } from 'ngx-bootstrap/chronos';

export const bnLocale: LocaleData = {
    months: ['জানুয়ারি', 'ফেব্রুয়ারি', 'মার্চ', 'এপ্রিল', 'মে', 'জুন', 'জুলাই', 'আগস্ট', 'সেপ্টেম্বর', 'অক্টোবর', 'নভেম্বর', 'ডিসেম্বর'],
    monthsShort: ['জানু', 'ফেব্রু', 'মার্চ', 'এপ্রি', 'মে', 'জুন', 'জুলা', 'আগ', 'সেপ্টে', 'অক্টো', 'নভে', 'ডিসে'],
    weekdays: ['রবিবার', 'সোমবার', 'মঙ্গলবার', 'বুধবার', 'বৃহস্পতিবার', 'শুক্রবার', 'শনিবার'],
    weekdaysShort: ['রবি', 'সোম', 'মঙ্গল', 'বুধ', 'বৃহস্পতি', 'শুক্র', 'শনি'],
    weekdaysMin: ['র', 'সো', 'ম', 'বু', 'বৃ', 'শু', 'শ'],
    longDateFormat: {
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY LT',
        LLLL: 'dddd, D MMMM YYYY LT',
        LT: 'HH:mm',
        LTS: 'HH:mm:ss'
    },
    calendar: {
        sameDay: '[আজ] LT',
        nextDay: '[আগামীকাল] LT',
        nextWeek: 'dddd [দিন] LT',
        lastDay: '[গতকাল] LT',
        lastWeek: '[গত] dddd [দিন] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: '%s পরে',
        past: '%s আগে',
        s: 'কয়েক সেকেন্ড',
        m: 'এক মিনিট',
        mm: '%d মিনিট',
        h: 'এক ঘণ্টা',
        hh: '%d ঘণ্টা',
        d: 'এক দিন',
        dd: '%d দিন',
        M: 'এক মাস',
        MM: '%d মাস',
        y: 'এক বছর',
        yy: '%d বছর'
    },
    ordinal: (number: number) => `${number}`,
    week: {
        dow: 0,
        doy: 6
    }
};
