import { Component, OnDestroy, OnInit } from '@angular/core';
import { Assessment, AssessmentService, AssessmentStatus, AssessmentType, MemberOnboardingService, Group, GroupService } from '@services/member';
import { LABELS, LanguageService } from '@services/public';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subscription, map } from 'rxjs';
import { MemberAssessmentExternalCreateOrUpdateComponent } from '../assessment';
import { Router } from '@angular/router';
import { JDate } from '@util';

const PAGE_PATH: string = 'pages.member.tasks.page';

@Component({
  selector: 'app-member-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class MemberTasksComponent implements OnInit, OnDestroy {

  assessments: Array<Assessment> = [];
  inprogressAssessmentCount: any;
  pendingAssessmentCount: any;
  show: number = 5;
  showbutton: string = "View More";
  showbuttonIcon: string = "double-down";
  externalAssessments: any = [];
  externalAssessmentsCompleted: number = 0;
  groups: Array<Group> = [];

  // Page subscriptions.
  private _subscriptions: Subscription = new Subscription();

  // Page langauge.
  page: {[key: string]: string} = {
    assessments: '',
    inProgress: '',
    pending: '',
    startsOn: '',
    resume: '',
    externalAssessments: '',
    create: '',
    viewAssessmentHistory: '',
    skillBuilders: ''
  }
  labels: {[key: string]: string} = {
    [LABELS.COMPLETED]: '',
    [LABELS.GROUP]: '',
    [LABELS.NAME]: '',
    [LABELS.STATUS]: '',
    [LABELS.VIEW_ALL]: '',
    [LABELS.VIEW_LESS]: '',
    [LABELS.VIEW_MORE]: ''
  }

  constructor(
    private _assessmentSvc: AssessmentService,
    private _groupSvc: GroupService,
    private _languageSvc: LanguageService,
    private _modalSvc: BsModalService,
    private _router: Router,
    private _memberOnboardingSvc: MemberOnboardingService
  ) { }

  ngOnInit(): void {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        for (const key in this.page) {
          this._languageSvc.template(this.page[key]).then(
            value => this.page[key] = value
          );
        }
      }
    );
    this._languageSvc.getLabels(this.labels);

    this.loadAssessments(new JDate().toString());

    this.loadExternalList();

    this._assessmentSvc
    .getExternalAssessmentsCompleted()
    .pipe(
      map((response: any) => {
        if (!!response.count) {
          this.externalAssessmentsCompleted = response.count;
        }
      }).bind(this)
    ).subscribe();

    // Load groups.
    this._subscriptions.add(
      this._groupSvc.groups.subscribe((next) => {
        this.groups = next.filter((group) => group.member);
      })
    );
  }

  showMoreIcon() {
    if (this.assessments.length > 5) {
      if (this.show == 5 && this.assessments.length !== this.show) {
        this.showbuttonIcon = "double-up";
        this.showbutton = this.labels.viewLess  ;
        this.show = this.assessments.length;
      } else {
        this.showbuttonIcon = "double-down";
        this.showbutton = this.labels.viewMore;
        this.show = 5;
      }
    }
  }

  loadAssessments(date: any) {
    let dateToday = new Date();
    this._assessmentSvc
      .getAssessments(date)
      .pipe(
        map((response: any) => {
          let assessmentsResponse = response;
          let activeAssessments = [];
          let scheduledAssessments = [];
          if (!!assessmentsResponse) this.assessments = assessmentsResponse;
          this.assessments.forEach((assessment: any) => {
            if (assessment.status == 'ACTIVE' || assessment.status == 'SCHEDULED') {
              let daysUntilStart = this.daysBetweenDates(dateToday, assessment.startDate);
              if (daysUntilStart < 31) {
                let arrayToPush = assessment.status == 'ACTIVE' ? activeAssessments : scheduledAssessments;
                return arrayToPush.push({...assessment, daysUntilStart: daysUntilStart})
              }
            }
          })
          activeAssessments = this.sortAssessmentsByStartDate(activeAssessments)
          scheduledAssessments = this.sortAssessmentsByStartDate(scheduledAssessments)
          const activeAssessmentCount = activeAssessments.length;
          const pendingAssessmentCount = scheduledAssessments.length;
          this.inprogressAssessmentCount =
            activeAssessmentCount < 10
              ? this.padLeft(activeAssessmentCount.toString(), "0", 2)
              : activeAssessmentCount;
          this.pendingAssessmentCount =
            pendingAssessmentCount < 10
              ? this.padLeft(pendingAssessmentCount.toString(), "0", 2)
              : pendingAssessmentCount;
          this.assessments = [...activeAssessments, ...scheduledAssessments]
        }).bind(this)
      )
      .subscribe();
  }

  // returns negative number if startDate comes after dateToCompare (past date)
  // returns positive number if startDate comes before dateToCompare (future date)
  daysBetweenDates(startDate, dateToCompare) {
    let fullDay = 86400000;
    let dateToday: any = startDate;
    let dateStart: any = new Date(dateToCompare);
    return Math.round((dateToday - dateStart) / fullDay) * -1
  }

  sortAssessmentsByStartDate(assessments): Array<Assessment> {
    return assessments.sort((item1, item2) => {
      if ( item1.daysUntilStart < item2.daysUntilStart ) return -1;
      if ( item2.daysUntilStart < item1.daysUntilStart ) return 1;
      return 0
    })
  }

  padLeft(text: string, padChar: string, size: number): string {
    return (String(padChar).repeat(size) + text).substr(size * -1, size);
  }

  loadExternalList() {
    // Load assessments.
    // this.p = 1;
    this._assessmentSvc
      .getExternals()
      .pipe(
        map((response: any) => {
          if (!!response) this.externalAssessments = response;
          this.externalAssessments.forEach((assessment) => {
            const propertiesToCapitalize = [
              this.labels.assessmentName,
              this.labels.firstName,
              this.labels.lastName,
            ];
            propertiesToCapitalize.forEach((property) => {
              if (assessment[property]) {
                assessment[property] = this.capitalizeFirstLetter(
                  assessment[property]
                );
              }
            });
          });
          this.externalAssessments.sort((a, b) => {
            if (a.status === b.status) return 0;
            if (a.status === AssessmentStatus.INACTIVE) return -1;
            if (a.status === AssessmentStatus.COMPLETED) return 1;
            if (b.status === AssessmentStatus.INACTIVE) return 1;
            return -1;
          });
        }).bind(this)
      )
      .subscribe();
  }

  capitalizeFirstLetter(value) {
    return value
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  onCreateExternalAssessmentClick() {
    this._modalSvc.show(
      MemberAssessmentExternalCreateOrUpdateComponent,
      {
        class: "modal-md-c modal-dialog-centered",
        backdrop: this._memberOnboardingSvc.isRunning? "static": true,
      }
    );
  }

  startAssessment(assessment: Assessment) {
    if (assessment.type === AssessmentType.PEER)
      this._router.navigate(["/member/assessment/peer", assessment.uuid]);
    if (assessment.type === AssessmentType.LEADER)
      this._router.navigate(["/member/assessment/leader", assessment.uuid]);
    else this._router.navigate(["/member/assessment/self", assessment.uuid]);
  }

  getGroupName(uuid: string): string {
    const group = this.groups.find((group) => group.uuid === uuid);
    if (!group) return this.labels.unknownGroup;
    return group.name;
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

}
