// Esperanto
import { LocaleData } from 'ngx-bootstrap/chronos';

export const eoLocale: LocaleData = {
    months: ['Januaro', 'Februaro', 'Marto', 'Aprilo', 'Majo', 'Junio', 'Julio', 'Aŭgusto', 'Septembro', 'Oktobro', 'Novembro', 'Decembro'],
    monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aŭg', 'Sep', 'Okt', 'Nov', 'Dec'],
    weekdays: ['Dimanĉo', 'Lundo', 'Mardo', 'Merkredo', 'Ĵaŭdo', 'Vendredo', 'Sabato'],
    weekdaysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Ĵaŭ', 'Ven', 'Sab'],
    weekdaysMin: ['Di', 'Lu', 'Ma', 'Me', 'Ĵa', 'Ve', 'Sa'],
    longDateFormat: {
        L: 'YYYY-MM-DD',
        LL: 'D[-a de] MMMM, YYYY',
        LLL: 'D[-a de] MMMM, YYYY LT',
        LLLL: 'dddd, [la] D[-a de] MMMM, YYYY LT',
        LT: 'HH:mm',
        LTS: 'HH:mm:ss'
    },
    calendar: {
        sameDay: '[Hodiaŭ je] LT',
        nextDay: '[Morgaŭ je] LT',
        nextWeek: 'dddd [je] LT',
        lastDay: '[Hieraŭ je] LT',
        lastWeek: '[Pasinta] dddd [je] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: 'post %s',
        past: 'antaŭ %s',
        s: 'sekundoj',
        m: 'minuto',
        mm: '%d minutoj',
        h: 'horo',
        hh: '%d horoj',
        d: 'tago',
        dd: '%d tagoj',
        M: 'monato',
        MM: '%d monatoj',
        y: 'jaro',
        yy: '%d jaroj'
    },
    ordinal: (number: number) => `${number}a`,
    week: {
        dow: 1,
        doy: 4
    }
};
