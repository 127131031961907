import { ExposedPromise } from "./exposed-promise";

interface Queue {
  key: Symbol;
  func: () => Promise<void>;
  cancel: () => void;
}

/**
 * A FIFO queue for functions using the parameters (next, args).
 */
export class FunctionQueue {
  private _queue: Array<Queue> = [];
  private _running: boolean = false;
  private _promise: ExposedPromise<void> = new ExposedPromise<void>();

  constructor() {
    this._promise.resolve();
  }

  get promise(): Promise<void> {
    return this._promise.toPromise();
  }

  /**
   * Adds a new function to the end of the queue.
   *
   * @param thisArg The value to use in the function for 'this'.
   * @param func The function to be ran. Async functions are awaited for their
   *  return.
   * @param args Optional arguments to be passed into the function.
   * @param auto Defaults to true. Set to false if using next().
   * @returns A promise for the return value of the function.
   */
  add<T = any, D = any>(
    thisArg: any,
    func: (next: () => Promise<void>, args: any) => T,
    args?: any,
    auto: boolean = true,
    cancelValue?: D
  ): Promise<T | D> {
    // Generate the queue entry.
    const promise: ExposedPromise<any> = new ExposedPromise<any>();
    const key: Symbol = Symbol();
    const boundNext: () => Promise<void> = this._next.bind(this, key);
    const boundFunction: () => any = func.bind(thisArg, boundNext, args);
    this._queue.push({
      key,
      func: async () => {
        promise.resolve(await boundFunction());
        if (auto) boundNext();
      },
      cancel: () => {
        promise.resolve(cancelValue);
      }
    });

    // Start the queue running if it is stopped.
    if (!this._running) {
      this._running = true;
      this._promise = new ExposedPromise<void>();
      this._queue[0].func();
    }

    // Return a promise for the function's return value.
    return promise.toPromise();
  }

  /**
   * Cancels all scheduled functions.
   */
  cancel(): void {
    if (this._queue.length > 1) {
      const cancelled: Array<Queue> = this._queue.splice(1);
      for (const item of cancelled) item.cancel();
    }
  }

  /**
   * Executes the next function in the queue.
   * Async so the previous function can return a value immediately.
   * Do not use await.
   */
  private async _next(key: Symbol): Promise<void> {
    // Skip if next is called multiple times in the same function.
    if (!this._queue[0] || this._queue[0].key !== key) return;

    // Remove the previous function from the queue.
    this._queue.splice(0, 1);

    // Stop the queue if there are no more functions.
    if (!this._queue[0]) {
      this._promise.resolve();
      this._running = false;
      return;
    }

    // Run the next function in the queue.
    this._queue[0].func();
  }
}

export function functionWrapper(func: () => any): () => void {
  return () => { func(); };
}
