// Javanese

import { LocaleData } from "ngx-bootstrap/chronos";

export const jvLocale: LocaleData = {
  months: ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'],
  monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des'],
  weekdays: ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'],
  weekdaysShort: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
  weekdaysMin: ['Mi', 'Se', 'Se', 'Ra', 'Ka', 'Ju', 'Sa'],
  longDateFormat: {
      L: 'DD/MM/YYYY',
      LL: 'D MMMM YYYY',
      LLL: 'D MMMM YYYY LT',
      LLLL: 'dddd, D MMMM YYYY LT',
      LT: 'HH:mm',
      LTS: 'HH:mm:ss'
  },
  calendar: {
      sameDay: '[Dina iki] LT',
      nextDay: '[Esuk] LT',
      nextWeek: 'dddd [ngarep] LT',
      lastDay: '[Kemarin] LT',
      lastWeek: '[Pungkasan] dddd [ngarep] LT',
      sameElse: 'L'
  },
  relativeTime: {
      future: '%s maneh',
      past: '%s kepungkur',
      s: 'sawetara detik',
      m: 'satu menit',
      mm: '%d menit',
      h: 'satu jam',
      hh: '%d jam',
      d: 'satu dina',
      dd: '%d dina',
      M: 'satu wulan',
      MM: '%d wulan',
      y: 'satu taun',
      yy: '%d taun'
  },
  ordinal: (number: number) => `${number}`,
  week: {
      dow: 1,
      doy: 4
  }
};
