<nav class="main-header navbar navbar-expand navbar-white navbar-light flex-grow-1">
  <div class="container align-items-start align-items-lg-center">
    <ul class="logoMain pt-1 pt-md-0 d-flex">
      <li class="logoInner d-flex align-items-center">
        <a routerLink="dashboard">
          <img src="assets/img/logo.svg" alt="" />
        </a>
      </li>
      <li *ngIf="isGhost" class="d-flex align-items-center ml-3">
        <button class="btn btn-primary" (click)="ghostClicked(template)">{{page.ghost}}</button>
      </li>
    </ul>
    <ng-template #template>
      <div class="modal-body text-center modal-confirm">
        <div class="modal-body">
          <div class="msg-wrapper">
            <p class="mt-3 text-center delete-msg">
              {{page.enterUsersEmail}}
            </p>
          </div>
          <input [(ngModel)]="userEmail">
        </div>
        <div class="pt-4  border-top">
          <button
            (click)="decline()"
            type="button"
            class="btn btn-md ml-3">
            {{page.cancel}}
          </button>
          <button
            (click)="ghost(userEmail)"
            type="button"
            class="btn btn-md btn-primary">
            {{page.ghost}}
          </button>
        </div>
      </div>
    </ng-template>

    <ul class="navbar-nav ml-auto nav-sm">
      <!-- <div class="btn-row-c d-none d-md-flex align-items-center pl-4 pl-md-0 pr-0 pr-sm-3 pr-md-4" *ngIf="isMobileNavTab">
        <button class="btn btn-secondary btn-secondary-sm border-white btn-sm-header" (click)="openWeeklyChallengePopup()">
          Weekly Challenges
        </button>
      </div> -->
      <!-- <div class="align-items-center d-flex d-md-none pl-4" *ngIf="isMobileNavTab">
        <button class="btn" href="" (click)="openWeeklyChallengePopup()">
          <img src="assets/img/weekly-challenge-mob.svg" alt="" />
        </button>
      </div> -->
      <ul class="navbar-nav justify-content-end">
        <!-- <li class="calendar-modal d-flex align-items-center">
          <button class="circle-purple-sm" href=""><img src="assets/img/calendar-check.svg" alt="Calendar" />
          </button>
        </li>
        <li class="divider">
          <span></span>
        </li> -->
        <li class="userProfile">
          <div class="nameCircle">{{ name?.charAt(0) }}</div>
          <span>{{page.hi}} {{ name }}</span>
        </li>
        <li class="divider">
          <span></span>
        </li>
        <div class="menu-language">
          <li class="nav-item loggleBtn mobile-burger">
            <a class="nav-link" (click)="toggleMenu.emit()" role="button"><i class="fas fa-bars"></i></a>
          </li>
          <li>
            <app-language-select></app-language-select>
          </li>
        </div>
        <li class="nav-item onboarding-logout-btn">
          <a (click)="onboardingLogout()" class="nav-link">
            <p>{{page.logout}}</p>
          </a>
        </li>
      </ul>
    </ul>
  </div>
</nav>

<nav *ngIf="displayMobileNavTab()" class="mobile-nav">
  <ul class="mobile-nav-ul">
    <li routerLinkActive="active">
      <a routerLink="/member/dashboard" >
        <div class="d-flex align-items-center justify-content-center">
          <span class="active-circle">
            <img
              class="flex-shrink-0 img"
              [alt]="page.home"
              [src]="
                route.url.includes('member/dashboard/activities') ?
                'assets/img/Home-Active.svg' :
                'assets/img/home.svg'
              "
            >
          </span>
        </div>
        <span class="active-link pt-2">{{page.home}}</span>
      </a>  
    </li>
    <li routerLinkActive="active">
      <a routerLink="/member/tasks">
        <div class="d-flex align-items-center justify-content-center">
          <span class="active-circle">
            <img
              class="flex-shrink-0 img"
              [alt]="page.clipboard"
              [src]="
                route.url.includes('member/tasks') ?
                'assets/img/Clipboard-Active.svg' :
                'assets/img/Clipboard.svg'
              "
            >
          </span> 
        </div>
      <span class="active-link pt-2">{{page.clipboard}}</span>
    </a>
  </li>
    <li routerLinkActive="active">
      <a routerLink="/member/dashboard/reports">
        <div class="d-flex align-items-center justify-content-center">
          <span class="active-circle">
            <img
              class="flex-shrink-0 img"
              [alt]="page.reports"
              [src]="
                route.url.includes('member/dashboard/reports') ?
                'assets/img/Reports-Active.svg' :
                'assets/img/reports.svg'
              "
            >
          </span>
        </div>
        <span class="active-link pt-2">{{page.reports}}</span>
      </a>
    </li>
    <li routerLinkActive="active">
      <a routerLink="/member/dashboard/rewards">
        <div class="d-flex align-items-center justify-content-center">
          <span class="active-circle">
            <img
              class="flex-shrink-0 img"
              [alt]="page.rewards"
              [src]="
                route.url.includes('member/dashboard/rewards') ?
                'assets/img/Rewards-Active.svg' :
                'assets/img/Rewards.svg'
              "
            >
          </span>
        </div>
        <span class="active-link pt-2">{{page.rewards}}</span>
      </a>
    </li>
    <li routerLinkActive="active">
      <a routerLink="/member/settings">
        <div class="d-flex align-items-center justify-content-center">
          <span class="active-circle">
            <img
              class="flex-shrink-0 img"
              [alt]="page.settings"
              [src]="
                route.url.includes('member/settings') ?
                'assets/img/Settings-Active.svg' :
                'assets/img/Settings.svg'
              "
            >
          </span>
        </div>
        <span class="active-link pt-2">{{page.settings}}</span>
      </a>
    </li>
  </ul>
</nav>
