// Konkani
import { LocaleData } from 'ngx-bootstrap/chronos';

export const gomLocale: LocaleData = {
    months: ['जानेवारी', 'फेब्रुवारी', 'मार्च', 'एप्रिल', 'मे', 'जून', 'जुलै', 'ऑगस्ट', 'सप्टेंबर', 'ऑक्टोबर', 'नोव्हेंबर', 'डिसेंबर'],
    monthsShort: ['जाने', 'फेब', 'मार्च', 'एप्र', 'मे', 'जून', 'जुल', 'ऑग', 'सप्ट', 'ऑक्ट', 'नोव्ह', 'डिस'],
    weekdays: ['रविवार', 'सोमवार', 'मंगळवार', 'बुधवार', 'गुरुवार', 'शुक्रवार', 'शनिवार'],
    weekdaysShort: ['रवि', 'सोम', 'मंग', 'बुध', 'गुरू', 'शुक्र', 'शनि'],
    weekdaysMin: ['र', 'सो', 'म', 'बु', 'गु', 'शु', 'श'],
    longDateFormat: {
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY LT',
        LLLL: 'dddd, D MMMM YYYY LT',
        LT: 'HH:mm',
        LTS: 'HH:mm:ss'
    },
    calendar: {
        sameDay: '[आज] LT',
        nextDay: '[उद्या] LT',
        nextWeek: 'dddd [ला] LT',
        lastDay: '[काल] LT',
        lastWeek: '[गेल्या] dddd [ला] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: '%s नंतर',
        past: '%s पूर्वी',
        s: 'काही सेकंद',
        m: 'एक मिनिट',
        mm: '%d मिनिटे',
        h: 'एक तास',
        hh: '%d तास',
        d: 'एक दिवस',
        dd: '%d दिवस',
        M: 'एक महिना',
        MM: '%d महिने',
        y: 'एक वर्ष',
        yy: '%d वर्षे'
    },
    ordinal: (number: number) => `${number}`,
    week: {
        dow: 1,
        doy: 4
    }
};
