import { Injectable } from '@angular/core';
import { ExposedPromise, JClone } from '@util';
import {
  arLocale,
  bgLocale,
  caLocale,
  csLocale,
  daLocale,
  deLocale,
  defineLocale,
  enGbLocale,
  esLocale,
  etLocale,
  fiLocale,
  frLocale,
  glLocale,
  heLocale,
  hiLocale,
  hrLocale,
  huLocale,
  idLocale,
  itLocale,
  jaLocale,
  kaLocale,
  kkLocale,
  koLocale,
  ltLocale,
  lvLocale,
  mnLocale,
  nbLocale,
  nlLocale,
  plLocale,
  ptBrLocale,
  roLocale,
  ruLocale,
  skLocale,
  slLocale,
  sqLocale,
  svLocale,
  thLocale,
  trLocale,
  ukLocale,
  viLocale,
  zhCnLocale
} from 'ngx-bootstrap/chronos';

import { 
  akLocale,
  amLocale,
  asLocale,
  ayLocale,
  azLocale,
  beLocale,
  bhoLocale,
  bmLocale,
  bnLocale,
  bsLocale,
  ckbLocale,
  coLocale,
  dvLocale,
  eeLocale,
  eoLocale,
  euLocale,
  faLocale,
  fjLocale,
  gaLocale,
  gomLocale,
  haLocale,
  hawLocale,
  htLocale,
  hyLocale,
  igLocale,
  iloLocale,
  isLocale,
  jvLocale,
  kmLocale,
  knLocale,
  kriLocale,
  kyLocale,
  laLocale,
  lbLocale,
  lgLocale,
  lnLocale,
  loLocale,
  lusLocale,
  mgLocale,
  miLocale,
  mkLocale,
  mlLocale,
  mrLocale,
  msLocale,
  mtLocale,
  myLocale,
  neLocale,
  nyLocale,
  omLocale,
  orLocale,
  paLocale,
  psLocale,
  quLocale,
  rwLocale,
  sdLocale,
  siLocale,
  smLocale,
  snLocale,
  soLocale,
  srLocale,
  stLocale,
  suLocale,
  swLocale,
  taLocale,
  teLocale,
  tgLocale,
  tiLocale,
  tkLocale,
  tsLocale,
  ttLocale, 
} from './locales';

export enum LANGUAGE_CODES {
  ARA = 'ara', // ar: Arabic
  AKA = 'aka', // ak: Akan
  AMH = 'amh', // am: Amharic
  ASM = 'asm', // as: Assamese
  AYM = 'aym', // ay: Aymara
  AZE = 'aze', // az: Azerbaijani
  BAM = 'bam', // bm: Bambara
  BEL = 'bel', // be: Belarusian
  BEN = 'ben', // bn: Bengali
  BHO = 'bho', // bh: Bhojpuri
  BOS = 'bos', // bs: Bosnian
  BUL = 'bul', // bg: Bulgarian
  CAT = 'cat', // ca: Catalan
  CES = 'ces', // cs: Czech
  COS = 'cos', // co: Corsican
  DAN = 'dan', // da: Danish
  DEU = 'deu', // de: German
  DIV = 'div', // dv: Divehi
  EPO = 'epo', // eo: Esperanto
  ENG = 'eng', // en/en-gb: English/UK English
  EST = 'est', // et: Estonian
  EUS = 'eus', // eu: Basque
  EWE = 'ewe', // ee: Ewe
  FAS = 'fas', // fa: Persian
  FIN = 'fin', // fi: Finnish
  FIJ = 'fij', // fj: Fijian
  FRA = 'fra', // fr: French
  GLE = 'gle', // ga: Irish
  GLG = 'glg', // gl: Galician
  HAT = 'hat', // ht: Haitian Creole
  HAU = 'hau', // ha: Hausa
  HAW = 'haw', // haw: Hawaiian
  HEB = 'heb', // he: Hebrew
  HIN = 'hin', // hi: Hindi
  HRV = 'hrv', // hr: Croatian
  HUN = 'hun', // hu: Hungarian
  HYE = 'hye', // hy: Armenian
  IGB = 'igb', // ig: Igbo
  ILO = 'ilo', // ilo: Iloko
  IND = 'ind', // id: Indonesian
  ISL = 'isl', // is: Icelandic
  ITA = 'ita', // it: Italian
  JPN = 'jpn', // ja: Japanese
  JAV = 'jav', // jv: Javanese
  KAN = 'kan', // kn: Kannada
  KAT = 'kat', // ka: Georgian
  KAZ = 'kaz', // kk: Kazakh
  KHM = 'khm', // km: Khmer
  KIN = 'kin', // rw: Kinyarwanda
  KOR = 'kor', // ko: Korean
  KOK = 'kok', // kok: Konkani
  KRI = 'kri', // kri: Krio
  KUR = 'kur', // ku: Kurdish
  KIR = 'kir', // ky: Kyrgyz
  LAT = 'lat', // la: Latin
  LAO = 'lao', // lo: Lao
  LAV = 'lav', // lv: Latvian
  LIT = 'lit', // lt: Lithuanian
  LIN = 'lin', // ln: Lingala
  LTZ = 'ltz', // lb: Luxembourgish
  LUB = 'lub', // lu: Luba-Katanga/Tshiluba/Kiluba
  LUG = 'lug', // lg: Ganda/Luganda
  MAL = 'mal', // ml: Malayalam
  MAR = 'mar', // mr: Marathi
  MKD = 'mkd', // mk: Macedonian
  MLG = 'mlg', // mg: Malagasy
  MNE = 'mne', // mt: Maltese
  MNI = 'mni', // mni: Manipuri
  MLT = 'mlt', // mt: Maltese
  MON = 'mon', // mn: Mongolian
  MRI = 'mri', // mi: Maori
  MSA = 'msa', // ms: Malay
  MYA = 'mya', // my: Burmese
  NEP = 'nep', // ne: Nepali
  NLD = 'nld', // nl: Dutch
  NOR = 'nor', // no/nb: Norwegian
  NYA = 'nya', // ny: Chichewa/Chewa/Nyanja
  ORM = 'orm', // om: Oromo
  ORI = 'ori', // or: Oriya/Odia
  PAN = 'pan', // pa: Punjabi/Panjabi
  POL = 'pol', // pl: Polish
  POR = 'por', // pt/pt-br: Portugese/Brazillian Portugese
  PUS = 'pus', // ps: Pashto/Pushto
  QUE = 'que', // qu: Quechua
  RON = 'ron', // ro: Romanian
  RUS = 'rus', // ru: Russian
  SIN = 'sin', // si: Sinhala/Sinhalese
  SLK = 'slk', // sk: Slovak
  SLV = 'slv', // sl: Slovenian
  SMO = 'smo', // sm: Samoan
  SNA = 'sna', // sn: Shona
  SND = 'snd', // sd: Sindhi
  SOM = 'som', // so: Somali
  SOT = 'sot', // st: Southern Sotho/Sesotho
  SPA = 'spa', // es: Spanish
  SQI = 'sqi', // sq: Albanian
  SRP = 'srp', // sr: Serbian
  SUN = 'sun', // su: Sundanese/basa Sunda
  SWA = 'swa', // sw: Swahili
  SWE = 'swe', // sv: Swedish
  TAM = 'tam', // ta: Tamil
  TAT = 'tat', // tt: Tatar
  TEL = 'tel', // te: Telugu
  TGK = 'tgk', // tg: Tajik
  THA = 'tha', // th: Thai
  TIR = 'tir', // ti: Tigrinya
  TSO = 'tso', // ts: Tsonga
  TUK = 'tuk', // tk: Turkmen
  TUR = 'tur', // tr: Turkish
  UKR = 'ukr', // uk: Ukrainian
  VIE = 'vie', // vi: Vietnamese
  ZHO = 'zho', // zh/zh-cn: Chinese/PRC Chinese
}

// Define locales for calendars.
defineLocale(LANGUAGE_CODES.AKA, akLocale);
defineLocale(LANGUAGE_CODES.AMH, amLocale);
defineLocale(LANGUAGE_CODES.ARA, arLocale);
defineLocale(LANGUAGE_CODES.ASM, asLocale);
defineLocale(LANGUAGE_CODES.AYM, ayLocale);
defineLocale(LANGUAGE_CODES.AZE, azLocale);
defineLocale(LANGUAGE_CODES.BAM, bmLocale);
defineLocale(LANGUAGE_CODES.BEL, beLocale);
defineLocale(LANGUAGE_CODES.BEN, bnLocale);
defineLocale(LANGUAGE_CODES.BHO, bhoLocale);
defineLocale(LANGUAGE_CODES.BOS, bsLocale);
defineLocale(LANGUAGE_CODES.BUL, bgLocale);
defineLocale(LANGUAGE_CODES.CAT, caLocale);
defineLocale(LANGUAGE_CODES.COS, coLocale);
defineLocale(LANGUAGE_CODES.CES, csLocale);
defineLocale(LANGUAGE_CODES.DAN, daLocale);
defineLocale(LANGUAGE_CODES.DEU, deLocale);
defineLocale(LANGUAGE_CODES.DIV, dvLocale,);
defineLocale(LANGUAGE_CODES.ENG, {...enGbLocale,  longDateFormat: {
  L: 'MM/DD/YYYY',    // Example: 08/12/2024
  LL: 'MMMM D, YYYY',  // Example: August 12, 2024
  LLL: 'MMMM D, YYYY LT', // Example: August 12, 2024 14:30
  LLLL: 'dddd, MMMM D, YYYY LT', // Example: Monday, August 12, 2024 14:30
  LT: 'HH:mm',        // Example: 14:30
  LTS: 'HH:mm:ss'     // Example: 14:30:45
}});
defineLocale(LANGUAGE_CODES.EST, etLocale);
defineLocale(LANGUAGE_CODES.EPO, eoLocale);
defineLocale(LANGUAGE_CODES.EUS, euLocale);
defineLocale(LANGUAGE_CODES.EWE, eeLocale);
defineLocale(LANGUAGE_CODES.FIN, fiLocale);
defineLocale(LANGUAGE_CODES.FAS, faLocale);
defineLocale(LANGUAGE_CODES.FIJ, fjLocale);
defineLocale(LANGUAGE_CODES.FRA, frLocale);
defineLocale(LANGUAGE_CODES.GLE, gaLocale);
defineLocale(LANGUAGE_CODES.GLG, glLocale);
defineLocale(LANGUAGE_CODES.HAT, htLocale);
defineLocale(LANGUAGE_CODES.HAU, haLocale);
defineLocale(LANGUAGE_CODES.HAW, hawLocale);
defineLocale(LANGUAGE_CODES.HEB, heLocale);
defineLocale(LANGUAGE_CODES.HIN, hiLocale);
defineLocale(LANGUAGE_CODES.HRV, hrLocale);
defineLocale(LANGUAGE_CODES.HUN, huLocale);
defineLocale(LANGUAGE_CODES.HYE, hyLocale);
defineLocale(LANGUAGE_CODES.IND, idLocale);
defineLocale(LANGUAGE_CODES.IGB, igLocale);
defineLocale(LANGUAGE_CODES.ILO, iloLocale);
defineLocale(LANGUAGE_CODES.ISL, isLocale);
defineLocale(LANGUAGE_CODES.ITA, itLocale);
defineLocale(LANGUAGE_CODES.JPN, jaLocale);
defineLocale(LANGUAGE_CODES.JAV, jvLocale);
defineLocale(LANGUAGE_CODES.KAN, knLocale);
defineLocale(LANGUAGE_CODES.KAT, kaLocale);
defineLocale(LANGUAGE_CODES.KAZ, kkLocale);
defineLocale(LANGUAGE_CODES.KHM, kmLocale);
defineLocale(LANGUAGE_CODES.KIN, rwLocale);
defineLocale(LANGUAGE_CODES.KIR, kyLocale);
defineLocale(LANGUAGE_CODES.KOK, gomLocale);
defineLocale(LANGUAGE_CODES.KOR, koLocale);
defineLocale(LANGUAGE_CODES.KRI, kriLocale);
defineLocale(LANGUAGE_CODES.KUR, ckbLocale);
defineLocale(LANGUAGE_CODES.LAO, loLocale);
defineLocale(LANGUAGE_CODES.LAT, laLocale);
defineLocale(LANGUAGE_CODES.LAV, lvLocale);
defineLocale(LANGUAGE_CODES.LIN, lnLocale);
defineLocale(LANGUAGE_CODES.LIT, ltLocale);
defineLocale(LANGUAGE_CODES.LTZ, lbLocale);
defineLocale(LANGUAGE_CODES.LUB, lusLocale);
defineLocale(LANGUAGE_CODES.LUG, lgLocale);
defineLocale(LANGUAGE_CODES.MAL, mlLocale);
defineLocale(LANGUAGE_CODES.MAR, mrLocale);
defineLocale(LANGUAGE_CODES.MKD, mkLocale);
defineLocale(LANGUAGE_CODES.MLG, mgLocale);
defineLocale(LANGUAGE_CODES.MNE, mtLocale);
defineLocale(LANGUAGE_CODES.MLT, mtLocale);
defineLocale(LANGUAGE_CODES.MON, mnLocale);
defineLocale(LANGUAGE_CODES.MRI, miLocale);
defineLocale(LANGUAGE_CODES.MSA, msLocale);
defineLocale(LANGUAGE_CODES.MYA, myLocale);
defineLocale(LANGUAGE_CODES.NEP, neLocale);
defineLocale(LANGUAGE_CODES.NLD, nlLocale);
defineLocale(LANGUAGE_CODES.NOR, nbLocale);
defineLocale(LANGUAGE_CODES.NYA, nyLocale);
defineLocale(LANGUAGE_CODES.ORM, omLocale);
defineLocale(LANGUAGE_CODES.ORI, orLocale);
defineLocale(LANGUAGE_CODES.PAN, paLocale);
defineLocale(LANGUAGE_CODES.POL, plLocale);
defineLocale(LANGUAGE_CODES.PUS, psLocale);
defineLocale(LANGUAGE_CODES.POR, ptBrLocale);
defineLocale(LANGUAGE_CODES.QUE, quLocale);
defineLocale(LANGUAGE_CODES.RON, roLocale);
defineLocale(LANGUAGE_CODES.RUS, ruLocale);
defineLocale(LANGUAGE_CODES.SIN, siLocale);
defineLocale(LANGUAGE_CODES.SLK, skLocale);
defineLocale(LANGUAGE_CODES.SLV, slLocale);
defineLocale(LANGUAGE_CODES.SMO, smLocale);
defineLocale(LANGUAGE_CODES.SNA, snLocale);
defineLocale(LANGUAGE_CODES.SND, sdLocale);
defineLocale(LANGUAGE_CODES.SOM, soLocale);
defineLocale(LANGUAGE_CODES.SOT, stLocale);
defineLocale(LANGUAGE_CODES.SPA, esLocale);
defineLocale(LANGUAGE_CODES.SQI, sqLocale);
defineLocale(LANGUAGE_CODES.SRP, srLocale);
defineLocale(LANGUAGE_CODES.SUN, suLocale);
defineLocale(LANGUAGE_CODES.SWA, swLocale);
defineLocale(LANGUAGE_CODES.SWE, svLocale);
defineLocale(LANGUAGE_CODES.TAM, taLocale);
defineLocale(LANGUAGE_CODES.TAT, ttLocale);
defineLocale(LANGUAGE_CODES.TEL, teLocale);
defineLocale(LANGUAGE_CODES.TGK, tgLocale);
defineLocale(LANGUAGE_CODES.THA, thLocale);
defineLocale(LANGUAGE_CODES.TIR, tiLocale);
defineLocale(LANGUAGE_CODES.TSO, tsLocale);
defineLocale(LANGUAGE_CODES.TUK, tkLocale);
defineLocale(LANGUAGE_CODES.TUR, trLocale);
defineLocale(LANGUAGE_CODES.UKR, ukLocale);
defineLocale(LANGUAGE_CODES.VIE, viLocale);
defineLocale(LANGUAGE_CODES.ZHO, zhCnLocale);

export enum LABELS {
  ALL_DOMAINS = 'allDomains',
  ALL_GROUPS = 'allGroups',
  ALL_TIME = 'allTime',
  BACK = 'back',
  CANCEL = 'cancel',
  CLOSE = 'close',
  COMPLETED = 'completed',
  DAYS = 'days',
  DECLINED = 'declined',
  ERROR_DISCLAIMER = 'errorDisclaimer',
  ERROR_NO_HTML = 'errorNoHTML',
  ERROR_SELECT_OPTION = 'errorSelectOption',
  GOOD = 'good',
  GROUP = 'group',
  IMPROVEMENT = 'improvement',
  INSUFFICIENT_DATA = 'insufficientData',
  LEVEL = 'level',
  NAME = 'name',
  NEXT = 'next',
  OK = 'ok',
  ONBOARDING = 'onboarding',
  SAVE = 'save',
  SELECT_DATE = 'selectDate',
  STATUS = 'status',
  SUBMIT = 'submit',
  TO = 'to',
  TYPE_HERE = 'typeHere',
  VIEW_ALL = 'viewAll',
  VIEW_LESS = 'viewLess',
  VIEW_MORE = 'viewMore',
  YEAR = 'year',
  YEARS = 'years'
}

export interface Domain {
  uid: number;
  name: string;
  skills: Skill[];
}

export interface Skill {
  uid: number;
  name: string;
  definition: string;
  description: string;
  questions: Array<string>;
}

export interface Reading {
  intro: Array<string>;
  inventory: Array<string>;
  cycle: Array<object>;
  header: string;
  body: string;
}

const LABELS_PATH: string = 'labels';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  language: LANGUAGE_CODES;
  private _labels: { [key: string]: string } = {};
  private _initialize: ExposedPromise<void> = new ExposedPromise<void>();
  private _loadingLabels: ExposedPromise<void> = new ExposedPromise<void>();
  private _loading: Set<Promise<any>> =
    new Set<Promise<any>>([this._initialize]);
  private _compiled: { [key: string]: any } = {};
  private _loadPaths: (paths: Array<string>) => Promise<void>;

  constructor() { }

  initialize(): void {
    this._initialize.resolve();
    this._loading.delete(this._initialize);
    this.get([LABELS_PATH]).then(
      value => {
        if (
          typeof value[LABELS_PATH] !== 'object' ||
          value[LABELS_PATH] === null
        ) return;
        this._labels = value[LABELS_PATH];
        this._loadingLabels.resolve();
      }
    );
  }

  addLoader(loader: Promise<any>): void {
    this._loading.add(loader);
    loader.then(() => { this._loading.delete(loader) });
  }

  set compiled(compiled: any) {
    this._compiled = compiled;
  }

  set loadPaths(loadPath: (paths: Array<string>) => Promise<void>) {
    this._loadPaths = loadPath;
  }

  // Used in app.component.ts.
  get loadingLabels(): Promise<void> {
    return this._loadingLabels.toPromise();
  }

  async get(
    paths: Array<string>,
    data?: { [key: string]: { [key: string]: string } }
  ): Promise<{ [key: string]: any }> {
    // Wait for the language loader.
    await Promise.all(this._loading);

    // Attempt to locate values.
    const results: { [key: string]: any } = {};
    for (const path of paths) {
      try {
        const splitPath = path.split('.');
        let value = this._compiled;
        for (let i = 0; i < splitPath.length; i++) {
          value = value[splitPath[i]];
          if (typeof value === 'undefined') {
            value = null;
            break;
          }
        }
        results[path] =
          ((typeof value === 'object' && !value._loaded) ? null : value);
      } catch (e) {
        results[path] = null;
      }
    }

    // Attempt to load null values.
    const badPaths = paths.filter(path => results[path] === null);
    if (badPaths.length > 0) {
      const loader = this._loadPaths(badPaths);
      this.addLoader(loader);
      await loader;

      // Second attempt to locate values.
      for (const path of badPaths) {
        try {
          const splitPath = path.split('.');
          let value = this._compiled;
          for (let i = 0; i < splitPath.length; i++) {
            value = value[splitPath[i]];
            if (typeof value === 'undefined') {
              results[path] = null;
              break;
            }
          }
          results[path] =
            ((typeof value === 'object' && !value._loaded) ? null : value);
        } catch (e) {
          results[path] = null;
        }
      }
    }

    // Resolve template strings.
    if (!!data)
      for (const path in results)
        if (typeof results[path] === 'string')
          results[path] = await this.template(results[path], data[path]);

    // Return a deep copy.
    const resultsClone = JClone(results);
    this._deletePrivateFlags(resultsClone);
    return resultsClone;
  }

  async template(
    template: string,
    data?: { [key: string]: string }
  ): Promise<string> {
    await Promise.all(this._loading);

    // Resolve template strings.
    data = data || {};
    while (/\$\{language\:[^\}]+\}/.test(template)) {
      const variables = template.match(/\$\{language\:[^\}]+\}/g);
      for (const variable of variables) {
        const path = variable.slice(11, -1);
        const results = await this.get([path], {});
        if (typeof results[path] === 'string')
          template = template.replace(variable, results[path]);
      }
    }
    for (const key in data)
      template = template.replace('${data:' + key + '}', data[key]);

    return template;
  }

  getLabels(labels: { [key: string]: string }): void {
    for (const key in labels) labels[key] = this._labels[key];
  }

  objectToOrderedArray(obj: object): Array<any> {
    return Object.entries(obj).sort((a, b) => {
      if (a[0] < b[0]) return -1;
      if (a[0] > b[0]) return 1;
      return 0;
    }).map(value => value[1]);
  }

  private _deletePrivateFlags(value: { [key: string]: any }) {
    if (typeof value !== 'object' || value === null) return;
    delete value._loaded;
    Object.values(value).forEach(value => this._deletePrivateFlags(value));
  }

}
