// Punjabi
import { LocaleData } from 'ngx-bootstrap/chronos';

export const paLocale: LocaleData = {
    months: ['ਜਨਵਰੀ', 'ਫਰਵਰੀ', 'ਮਾਰਚ', 'ਅਪ੍ਰੈਲ', 'ਮਈ', 'ਜੂਨ', 'ਜੁਲਾਈ', 'ਅਗਸਤ', 'ਸਤੰਬਰ', 'ਅਕਤੂਬਰ', 'ਨਵੰਬਰ', 'ਦਿਸੰਬਰ'],
    monthsShort: ['ਜਨ', 'ਫਰ', 'ਮਾਰਚ', 'ਅਪ੍ਰੈਲ', 'ਮਈ', 'ਜੂਨ', 'ਜੁਲਾਈ', 'ਅਗ', 'ਸਤੰ', 'ਅਕਤੂ', 'ਨਵ', 'ਦਿਸ'],
    weekdays: ['ਐਤਵਾਰ', 'ਸੋਮਵਾਰ', 'ਮੰਗਲਵਾਰ', 'ਬੁਧਵਾਰ', 'ਵੀਰਵਾਰ', 'ਸ਼ੁਕਰਵਾਰ', 'ਸ਼ਨੀਵਾਰ'],
    weekdaysShort: ['ਐਤ', 'ਸੋਮ', 'ਮੰਗਲ', 'ਬੁਧ', 'ਵੀਰ', 'ਸ਼ੁਕ', 'ਸ਼ਨ'],
    weekdaysMin: ['ਐ', 'ਸੋ', 'ਮ', 'ਬੁ', 'ਵੀ', 'ਸ਼ੁ', 'ਸ਼'],
    longDateFormat: {
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY LT',
        LLLL: 'dddd, D MMMM YYYY LT',
        LT: 'HH:mm',
        LTS: 'HH:mm:ss'
    },
    calendar: {
        sameDay: '[ਅੱਜ] LT',
        nextDay: '[ਕਲ] LT',
        nextWeek: 'dddd [ਨੂੰ] LT',
        lastDay: '[ਕਲ] LT',
        lastWeek: '[ਪਿਛਲੇ] dddd [ਨੂੰ] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: '%s ਵਿੱਚ',
        past: '%s ਪਹਿਲਾਂ',
        s: 'ਕੁਝ ਸਕਿੰਟ',
        m: 'ਇੱਕ ਮਿੰਟ',
        mm: '%d ਮਿੰਟ',
        h: 'ਇੱਕ ਘੰਟਾ',
        hh: '%d ਘੰਟੇ',
        d: 'ਇੱਕ ਦਿਨ',
        dd: '%d ਦਿਨ',
        M: 'ਇੱਕ ਮਹੀਨਾ',
        MM: '%d ਮਹੀਨੇ',
        y: 'ਇੱਕ ਸਾਲ',
        yy: '%d ਸਾਲ'
    },
    ordinal: (number: number) => `${number}`,
    week: {
        dow: 0, // Sunday is the first day of the week
        doy: 6  // The week that contains Jan 6 is the first week of the year
    }
};
