// Armenian
import { LocaleData} from "ngx-bootstrap/chronos";

export const hyLocale: LocaleData = {
    months: ['Հունվար', 'Փետրվար', 'Մարտ', 'Ապրիլ', 'Մայիս', 'Հունիս', 'Հուլիս', 'Օգոստոս', 'Սեպտեմբեր', 'Հոկտեմբեր', 'Նոյեմբեր', 'Դեկտեմբեր'],
    monthsShort: ['Հուն', 'Փետ', 'Մար', 'Ապր', 'Մայ', 'Հուն', 'Հուլ', 'Օգս', 'Սեպ', 'Հոկ', 'Նոյ', 'Դեկ'],
    weekdays: ['Կիրակի', 'Երկուշաբթի', 'Երեքշաբթի', 'Չորեքշաբթի', 'Հինգշաբթի', 'Ուրբաթ', 'Շաբաթ'],
    weekdaysShort: ['Կի', 'Եր', 'Եր', 'Չո', 'Հի', 'Ուր', 'Շա'],
    weekdaysMin: ['Կի', 'Եր', 'Եր', 'Չո', 'Հի', 'Ուր', 'Շա'],
    longDateFormat: {
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY LT',
        LLLL: 'dddd, D MMMM YYYY LT',
        LT: 'HH:mm',
        LTS: 'HH:mm:ss'
    },
    calendar: {
        sameDay: '[Այսօր] LT',
        nextDay: '[Վարագույրը] LT',
        nextWeek: 'dddd [օրվա] LT',
        lastDay: '[Երեկ] LT',
        lastWeek: '[Անցած] dddd [օրվա] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: '%s հետո',
        past: '%s առաջ',
        s: 'կիրառելի',
        m: 'մեկ րոպե',
        mm: '%d րոպե',
        h: 'մեկ ժամ',
        hh: '%d ժամ',
        d: 'մեկ օր',
        dd: '%d օր',
        M: 'մեկ ամիս',
        MM: '%d ամիս',
        y: 'մեկ տարի',
        yy: '%d տարի'
    },
    ordinal: (number: number) => `${number}`,
    week: {
        dow: 1,
        doy: 4
    }
};
