// Sindhi
import { LocaleData } from 'ngx-bootstrap/chronos';

export const sdLocale: LocaleData = {
    months: ['جنوري', 'فيبروري', 'مارچ', 'اپريل', 'مئي', 'جون', 'جولاءِ', 'آگسٽ', 'سيپٽمبر', 'آڪٽوبر', 'نومبر', 'ڊسمبر'],
    monthsShort: ['جن', 'ف', 'مار', 'اپر', 'مئي', 'جو', 'جول', 'آگ', 'سيپ', 'آڪٽ', 'نوم', 'ڊس'],
    weekdays: ['آچر', 'سومر', 'اڱارو', 'اربع', 'خميس', 'جمع', 'ڇنڇر'],
    weekdaysShort: ['آچر', 'سوم', 'اڱ', 'اربع', 'خم', 'جمع', 'ڇن'],
    weekdaysMin: ['آ', 'سو', 'اڱ', 'ار', 'خم', 'جم', 'ڇ'],
    longDateFormat: {
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY LT',
        LLLL: 'dddd, D MMMM YYYY LT',
        LT: 'HH:mm',
        LTS: 'HH:mm:ss'
    },
    calendar: {
        sameDay: '[اڄ] LT',
        nextDay: '[سبا] LT',
        nextWeek: 'dddd [اڳتي] LT',
        lastDay: '[ڪل] LT',
        lastWeek: '[گزريل] dddd [۾] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: '۾ %s',
        past: '%s اڳ',
        s: 'چند سيڪنڊ',
        m: 'هڪ منٽ',
        mm: '%d منٽ',
        h: 'هڪ ڪلاڪ',
        hh: '%d ڪلاڪ',
        d: 'هڪ ڏينهن',
        dd: '%d ڏينهن',
        M: 'هڪ مهينو',
        MM: '%d مهينا',
        y: 'هڪ سال',
        yy: '%d سال'
    },
    ordinal: (number: number) => `${number}`,
    week: {
        dow: 1, // Monday is the first day of the week
        doy: 4  // The week that contains Jan 4 is the first week of the year
    }
};
