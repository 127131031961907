// Corsican
import { LocaleData } from 'ngx-bootstrap/chronos';

export const coLocale: LocaleData = {
    months: ['Ghjanvi', 'Frivaghju', 'Marzu', 'Aprili', 'Maghju', 'Ghjugnu', 'Lugliu', 'Aostu', 'Settembre', 'Ottobre', 'Nuvembre', 'Dicembre'],
    monthsShort: ['Ghj', 'Fri', 'Mar', 'Apr', 'Mag', 'Ghj', 'Lug', 'Aos', 'Set', 'Ott', 'Nuv', 'Dic'],
    weekdays: ['Dumenica', 'Luni', 'Marti', 'Mercuri', 'Ghjovi', 'Venneri', 'Sabbatu'],
    weekdaysShort: ['Dum', 'Lun', 'Mar', 'Mer', 'Ghj', 'Ven', 'Sab'],
    weekdaysMin: ['Du', 'Lu', 'Ma', 'Me', 'Ghj', 'Ve', 'Sa'],
    longDateFormat: {
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY LT',
        LLLL: 'dddd, D MMMM YYYY LT',
        LT: 'HH:mm',
        LTS: 'HH:mm:ss'
    },
    calendar: {
        sameDay: '[Oghje à] LT',
        nextDay: '[Dumane à] LT',
        nextWeek: 'dddd [à] LT',
        lastDay: '[Ieri à] LT',
        lastWeek: '[U] dddd [scorsu à] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: 'in %s',
        past: '%s fà',
        s: 'qualchi siconda',
        m: 'un minutu',
        mm: '%d minuti',
        h: 'un’ora',
        hh: '%d ore',
        d: 'un ghjornu',
        dd: '%d ghjorni',
        M: 'un mese',
        MM: '%d mesi',
        y: 'un annu',
        yy: '%d anni'
    },
    ordinal: (number: number) => `${number}`,
    week: {
        dow: 1,
        doy: 4
    }
};
