// Latin
import { LocaleData } from 'ngx-bootstrap/chronos';

export const laLocale: LocaleData = {
    months: ['Ianuarius', 'Februarius', 'Martius', 'Aprilis', 'Maius', 'Iunius', 'Iulius', 'Augustus', 'September', 'October', 'November', 'December'],
    monthsShort: ['Ian', 'Feb', 'Mar', 'Apr', 'Mai', 'Iun', 'Iul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    weekdays: ['Solis Dies', 'Lunae Dies', 'Martis Dies', 'Mercurii Dies', 'Iovis Dies', 'Veneris Dies', 'Saturni Dies'],
    weekdaysShort: ['Sol', 'Lun', 'Mar', 'Merc', 'Iov', 'Ven', 'Sat'],
    weekdaysMin: ['S', 'L', 'Ma', 'Me', 'I', 'V', 'Sa'],
    longDateFormat: {
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY LT',
        LLLL: 'dddd, D MMMM YYYY LT',
        LT: 'HH:mm',
        LTS: 'HH:mm:ss'
    },
    calendar: {
        sameDay: '[Hodie] LT',
        nextDay: '[Cras] LT',
        nextWeek: 'dddd [in] LT',
        lastDay: '[Heri] LT',
        lastWeek: '[Ultimo] dddd [in] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: 'in %s',
        past: '%s ago',
        s: 'pauca secunda',
        m: 'una minuta',
        mm: '%d minuta',
        h: 'una hora',
        hh: '%d hora',
        d: 'una dies',
        dd: '%d dies',
        M: 'una mensis',
        MM: '%d mensis',
        y: 'una annus',
        yy: '%d annus'
    },
    ordinal: (number: number) => `${number}`,
    week: {
        dow: 1,
        doy: 4
    }
};
