// Azerbaijani

import { LocaleData} from "ngx-bootstrap/chronos";

export const azLocale: LocaleData = {
    months: ['Yanvar', 'Fevral', 'Mart', 'Aprel', 'May', 'İyun', 'İyul', 'Avqust', 'Sentyabr', 'Oktyabr', 'Noyabr', 'Dekabr'],
    monthsShort: ['Yan', 'Fev', 'Mar', 'Apr', 'May', 'İyun', 'İyul', 'Avq', 'Sen', 'Okty', 'Noy', 'Dek'],
    weekdays: ['Bazar', 'Bazar ertəsi', 'Çərşənbə axşamı', 'Çərşənbə', 'Cümə axşamı', 'Cümə', 'Şənbə'],
    weekdaysShort: ['Baz', 'Baz', 'Çər', 'Çər', 'Cüm', 'Cüm', 'Şən'],
    weekdaysMin: ['B', 'B', 'Ç', 'Ç', 'C', 'C', 'Ş'],
    longDateFormat: {
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY LT',
        LLLL: 'dddd, D MMMM YYYY LT',
        LT: 'HH:mm',
        LTS: 'HH:mm:ss'
    },
    calendar: {
        sameDay: '[Bugün] LT',
        nextDay: '[Sabah] LT',
        nextWeek: 'dddd [günü] LT',
        lastDay: '[Dünən] LT',
        lastWeek: '[Keçən] dddd [günü] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: '%s sonra',
        past: '%s əvvəl',
        s: 'bir neçə saniyə',
        m: 'bir dəqiqə',
        mm: '%d dəqiqə',
        h: 'bir saat',
        hh: '%d saat',
        d: 'bir gün',
        dd: '%d gün',
        M: 'bir ay',
        MM: '%d ay',
        y: 'bir il',
        yy: '%d il'
    },
    ordinal: (number: number) => `${number}`,
    week: {
        dow: 1,
        doy: 4
    }
};
