// Icelandic

import { LocaleData } from "ngx-bootstrap/chronos";

export const isLocale: LocaleData = {
  months: ['Janúar', 'Febrúar', 'Mars', 'Apríl', 'Maí', 'Júní', 'Júlí', 'Ágúst', 'September', 'Október', 'Nóvember', 'Desember'],
  monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Maí', 'Júní', 'Júl', 'Ágú', 'Sep', 'Okt', 'Nóv', 'Des'],
  weekdays: ['Sunnudagur', 'Mánudagur', 'Þriðjudagur', 'Fimmtudagur', 'Föstudagur', 'Laugardagur'],
  weekdaysShort: ['Sun', 'Mán', 'Þri', 'Fím', 'Fös', 'Lau'],
  weekdaysMin: ['Su', 'Má', 'Þr', 'Fí', 'Fö', 'La'],
  longDateFormat: {
      L: 'DD/MM/YYYY',
      LL: 'D. MMMM YYYY',
      LLL: 'D. MMMM YYYY [kl.] LT',
      LLLL: 'dddd, D. MMMM YYYY [kl.] LT',
      LT: 'HH:mm',
      LTS: 'HH:mm:ss'
  },
  calendar: {
      sameDay: '[Í dag kl.] LT',
      nextDay: '[Á morgun kl.] LT',
      nextWeek: 'dddd [kl.] LT',
      lastDay: '[Í gær kl.] LT',
      lastWeek: '[Síðasta] dddd [kl.] LT',
      sameElse: 'L'
  },
  relativeTime: {
      future: '%s eftir',
      past: '%s síðan',
      s: 'nokkrar sekúndur',
      m: 'ein mínúta',
      mm: '%d mínútur',
      h: 'ein klukkustund',
      hh: '%d klukkustundir',
      d: 'ein dagur',
      dd: '%d dagar',
      M: 'ein mánuður',
      MM: '%d mánuðir',
      y: 'eitt ár',
      yy: '%d ár'
  },
  ordinal: (number: number) => `${number}`,
  week: {
      dow: 1,
      doy: 4
  }
};
