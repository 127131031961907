import { Component, ElementRef, OnInit, ViewChild, OnDestroy} from '@angular/core';
import { LanguageService } from '@services/public';
import { MemberOnboardingService, FinalCardService } from '@services/member';
import { config } from 'environment';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import { JDate } from '@util';

const PAGE_PATH: string = 'pages.member.walkthrough.page';

interface checklistItem {
  text: string;
  completed: boolean;
  subItems?: Array<{text:string, complete:boolean}>;
}

@Component({
  selector: 'app-final-onboarding',
  templateUrl: './final-onboarding.component.html',
  styleUrls: ['./final-onboarding.component.scss']
})
export class MemberDashboardActivitiesOnboardingComponent implements OnInit, OnDestroy{
  @ViewChild('finalCard') finalCard: ElementRef;
  step: number = 0; //Current step
  startDate: Date; //Start for the next weekly challenge
  absoluteValueOfDays: number = 0; //Absolute value of days till start
  isLastStep: boolean = true;
  slide = {
    type: '',
    title: '',
    subTitle: '',
    message: '',
    buttonLabel: '',
    checklistItems: [],
    itemsCompleted: 0,
    daysTillStart: 0,
    buttonType: '',
    isLast: false
  };
  page: {[key: string]: string} = {
    buttonLabelContinue: '',
    buttonLabelNext: '',
    listAvatar: '',
    listCelebrate: '',
    listCompleteTitle: '',
    listCompleteSubTitle: '',
    listCompleteTitleReady: '',
    listCompleteButtonLabel: '',
    listCompleteMessage: '',
    listCompleteMessageReady: '',
    listExternal: '',
    listTitle: '',
    listSkills: '',
    listSubTitleCelebrate: '',
    listSubTitleLearn: '',
    listSubTitleSkills: '',
    sublistCooperation: '',
    sublistEmotional: '',
    sublistInnovation: '',
    sublistManage: '',
    sublistBrain: '',
    sublistGoal: '',
    sublistJournal: '',
    sublistResilience: '',
  };

  constructor( 
    private _languageSvc: LanguageService,
    private _http: HttpClient,
    private _memberOnboardingSvc: MemberOnboardingService,
    private _finalCardSvc: FinalCardService
   ) 
    {}
  

  ngOnInit(): void {
    this.getStep().subscribe((data) => {
      if (!!data) {
        this.step = this.convertFlagToStep(data.flags);
        this.startDate = new Date(data?.start_date);
      }else return;
      if (this.step === 5) {
        window.location.reload.bind(window.location),
        this.loadPage();
        this._finalCardSvc.setFinalStatus(false);
      }
    });
  }
 
  onClose() {
    this.updateStep(63).subscribe((response) => {
    if (response.success) {
      window.location.reload();
      this.finalCard.nativeElement.classList.add('hide');
      document.body.classList.remove('final-onboarding');
      this._finalCardSvc.setFinalStatus(true);
     }
    });
  }

  getStep(): Observable<any> {
    return this._http.get<any>(`${config.apiBase}member/onboarding/view`);
  }

  convertFlagToStep(flag: number): number {
    for (let i: number = 0; i < 6; i++) {
      if (!(flag >> i & 1)) return i;
    }
    return 6;
  }

  updateStep(flags: number): Observable<any> {
    return this._http.put<any>(`${config.apiBase}member/onboarding/update`,{ flags }
    );
  }

  private _checklistSetup(step: number): {
    checklistItems: Array<checklistItem>,
    itemsCompleted: number
  } {
    const checklistItems: Array<checklistItem> = [
      { text: this.page.listAvatar, completed: false },
      { text: this.page.listAssessment+' >', completed: false },
      { text: this.page.listSkills+' >', completed: false },
      { text: this.page.listExternal, completed: false },
      { text: this.page.listCelebrate, completed: false }
    ];
    let itemsCompleted = 0;
    if (step > 0){
      for (let i = 0; i < step && i < checklistItems.length; i++) {
        checklistItems[i].completed = true;
        itemsCompleted++;
        if (step === 6) {
          if (i === 2) {
            checklistItems[i-1].subItems = [
              {text: this.page.sublistCooperation, complete: false},
              {text: this.page.sublistEmotional, complete: false},
              {text: this.page.sublistInnovation, complete: false}, 
              {text: this.page.sublistManage, complete: true},
            ];
          } else if (i === 3) {
            checklistItems[i-1].subItems = [
              {text: this.page.sublistBrain, complete: false},
              {text: this.page.sublistGoal, complete: true},
              {text: this.page.sublistJournal , complete: false},
              {text: this.page.sublistResilience, complete: false}
            ];
          }
        }
      }
    }
    return { checklistItems, itemsCompleted };
  }


  loadPage(): void {
    this._memberOnboardingSvc.emitOnboardingCompleted();
    document.body.classList.remove('onboarding');
    document.body.classList.add('final-onboarding');

     // Calculate how many days until next weekly challenge
     const today = new JDate(new Date());
     const start_date = new JDate(this.startDate);
     const startDays = JDate.compareDays(today, start_date);

     this.slide.daysTillStart = startDays < 0 ? 0 : startDays;
     this.absoluteValueOfDays = Math.abs(this.slide.daysTillStart - 7);

    this._languageSvc.get([PAGE_PATH]).then((value) => {
      if (
        typeof value[PAGE_PATH] !== 'object' ||
        value[PAGE_PATH] === null
      ) return;
      this.page = value[PAGE_PATH];
      for (const key in this.page)
        this._languageSvc.template(this.page[key]).then(
          value => this.page[key] = value);
      const { checklistItems, itemsCompleted } = this._checklistSetup(6);
      this.slide.checklistItems = checklistItems;
      this.slide.itemsCompleted = itemsCompleted;
      this.slide.type = 'checklist';
      this.slide.isLast = true;
      this.slide.title = this.slide.daysTillStart !== 0 ? 
        this.page.listCompleteTitle: this.page.listCompleteTitleReady;
      this.slide.subTitle= this.page.listCompleteSubTitle;
      this.slide.buttonLabel= this.page.listCompleteButtonLabel;
      this.slide.message = this.slide.daysTillStart !== 0 ? 
        this.page.listCompleteMessage: this.page.listCompleteMessageReady;
      this.slide.buttonType = 'continue';  
      
    });
    this.finalCard.nativeElement.classList.remove('hide');
  }

  ngOnDestroy(): void {
    document.body.classList.remove('final-onboarding');
  }


}
