// Shona
import { LocaleData } from 'ngx-bootstrap/chronos';

export const snLocale: LocaleData = {
    months: ['Ndira', 'Kukadzi', 'Kurume', 'Kubvumbi', 'Chivabvu', 'Chikumi', 'Chikunguru', 'Nyamavhuvhu', 'Gunyana', 'Gumiguru', 'Mbudzi', 'Zvita'],
    monthsShort: ['Ndi', 'Kuk', 'Kur', 'Kub', 'Chiv', 'Chik', 'Chik', 'Nyam', 'Gun', 'Gumi', 'Mbu', 'Zvi'],
    weekdays: ['Svondo', 'Muvhuro', 'Chipiri', 'Chitatu', 'China', 'Chishanu', 'Mugovera'],
    weekdaysShort: ['Svo', 'Muv', 'Chi', 'Chi', 'Chi', 'Chi', 'Mug'],
    weekdaysMin: ['S', 'M', 'C', 'C', 'C', 'C', 'M'],
    longDateFormat: {
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY LT',
        LLLL: 'dddd, D MMMM YYYY LT',
        LT: 'HH:mm',
        LTS: 'HH:mm:ss'
    },
    calendar: {
        sameDay: '[Nhasi] LT',
        nextDay: '[Mangwana] LT',
        nextWeek: 'dddd [week] LT',
        lastDay: '[Nezuro] LT',
        lastWeek: '[Past] dddd [week] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: 'mushure %s',
        past: '%s yapfuura',
        s: 'masekonzi mashoma',
        m: 'miniti imwe',
        mm: '%d maminitsi',
        h: 'awa rimwe',
        hh: '%d maawa',
        d: 'zuva rimwe',
        dd: '%d mazuva',
        M: 'mwedzi umwe',
        MM: '%d mwedzi',
        y: 'gore rimwe',
        yy: '%d makore'
    },
    ordinal: (number: number) => `${number}`,
    week: {
        dow: 1, // Monday is the first day of the week
        doy: 4  // The week that contains Jan 4 is the first week of the year
    }
};
