// Kyrgyz
import { LocaleData } from 'ngx-bootstrap/chronos';

export const kyLocale: LocaleData = {
    months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    monthsShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
    weekdays: ['Жекшемби', 'Дүйшөмбү', 'Шейшемби', 'Шаршемби', 'Бейшемби', 'Жума', 'Шейшемби'],
    weekdaysShort: ['Жек', 'Дүй', 'Шей', 'Шар', 'Бей', 'Жум', 'Шей'],
    weekdaysMin: ['Жк', 'Дш', 'Шй', 'Шр', 'Бш', 'Жм', 'Шй'],
    longDateFormat: {
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY LT',
        LLLL: 'dddd, D MMMM YYYY LT',
        LT: 'HH:mm',
        LTS: 'HH:mm:ss'
    },
    calendar: {
        sameDay: '[Бүгүн] LT',
        nextDay: '[Эртең] LT',
        nextWeek: 'dddd [күні] LT',
        lastDay: '[Кечээ] LT',
        lastWeek: '[Өткөн] dddd [күні] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: '%s ичинде',
        past: '%s мурун',
        s: 'бир нече секунд',
        m: 'бир мүнөт',
        mm: '%d мүнөт',
        h: 'бир саат',
        hh: '%d саат',
        d: 'бир күн',
        dd: '%d күн',
        M: 'бир ай',
        MM: '%d ай',
        y: 'бир жыл',
        yy: '%d жыл'
    },
    ordinal: (number: number) => `${number}`,
    week: {
        dow: 1,
        doy: 4
    }
};
