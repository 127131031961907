// Sundanese
import { LocaleData } from 'ngx-bootstrap/chronos';

export const suLocale: LocaleData = {
    months: ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'],
    monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des'],
    weekdays: ['Minggu', 'Senén', 'Salasa', 'Rebo', 'Kemis', 'Jumaah', 'Saptu'],
    weekdaysShort: ['Min', 'Sen', 'Sal', 'Reb', 'Kem', 'Jum', 'Sap'],
    weekdaysMin: ['Mi', 'Se', 'Sa', 'Re', 'Ke', 'Ju', 'Sa'],
    longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'dddd, D MMMM YYYY HH:mm'
    },
    calendar: {
        sameDay: '[Dinten ieu pukul] LT',
        nextDay: '[Isukan pukul] LT',
        nextWeek: 'dddd [pukul] LT',
        lastDay: '[Kamari pukul] LT',
        lastWeek: 'dddd [kamari pukul] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: 'dina %s',
        past: '%s ka tukang',
        s: 'sabaraha detik',
        ss: '%d detik',
        m: 'saminut',
        mm: '%d menit',
        h: 'sa jam',
        hh: '%d jam',
        d: 'sadinten',
        dd: '%d dinten',
        M: 'sasasih',
        MM: '%d sasih',
        y: 'satangtu',
        yy: '%d taun'
    },
    ordinal: (num: number): string => num === 1 ? '1st' : `${num}`, // Example, customize based on rules
    week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4  // The week that contains Jan 4th is the first week of the year.
    }
};
