import { Component, OnInit, Input } from '@angular/core';
import { CardService } from '@services/member';
import { LanguageService } from '@services/public';

const PAGE_PATH: string = 'pages.member.walkthrough.page';

interface slide {
  type: string;
  title?: string;
  subTitle?: string;
  message?: string;
  buttonLabel?: string;
  buttonType?: string;
}

@Component({
  selector: 'app-view-card',
  templateUrl: './view-card.component.html',
  styleUrls: ['./view-card.component.scss']
})
export class ViewCardComponent implements OnInit {
  @Input() slide: slide;
  page: {[key: string]: string}= {
    buttonLabelContinue: '',
    buttonLabelNext: '',
    completionSubTitleAssessment: '',
    completionSubTitleOnboarding: '',
    completionSubTitleSkills: '',
    completionTitle: '',
  }
  constructor(
    private _cardService: CardService,
    private _languageSvc: LanguageService,
  ) { }

  ngOnInit(): void {
    this._languageSvc.get([PAGE_PATH]).then((value) => {
      if (
        typeof value[PAGE_PATH] !== 'object' ||
        value[PAGE_PATH] === null
      ) return;
      this.page = value[PAGE_PATH];
      for (const key in this.page)
        this._languageSvc.template(this.page[key]).then(
          value => this.page[key] = value);
    });
  }

  onClose() {
    this._cardService.emitCloseEvent(true);
  }

  onNext() {
    this._cardService.emitNextCardEvent(true);
  }

}
