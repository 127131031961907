import { Injectable, NgZone } from '@angular/core';

interface Callback {
  callback: () => void | Promise<void>;
  delay: number;
  executionKey: symbol;
}

@Injectable({
  providedIn: 'root'
})
export class PublicAsyncDelayService {

  private _callbacks: Map<symbol, Callback> = new Map<symbol, Callback>();

  constructor(
    private _ngZone: NgZone
  ) { }

  /**
   * Delays a function call until a later event cycle.
   * @param key A unique key for the function being called.
   *  Makes duplicate asyncDelay calls only execute once.
   * @param callback The function to be called after the delay.
   * @param delay The minimum number of event cycles that must pass before the
   *  function is called. Resets on duplicate asyncDelay calls. Default is 1.
   * @returns Nothing.
   */
  asyncDelay = (
    key: symbol,
    callback: () => void | Promise<void>,
    delay?: number
  ): void => {
    const executionKey: symbol = Symbol();
    this._callbacks.set(key, { callback, delay: delay ?? 1, executionKey });
    setTimeout(this._handleCallback, 0, key, executionKey);
  }

  private _handleCallback: (
    functionKey: symbol,
    executionKey: symbol
  ) => void = (functionKey: symbol, executionKey: symbol) => {
    const callback = this._callbacks.get(functionKey);
    if (!callback || callback.executionKey !== executionKey) return;
    if (--callback.delay < 1) {
      this._callbacks.delete(functionKey);
      this._ngZone.run(callback.callback);
    } else setTimeout(this._handleCallback, 0, functionKey, executionKey);
  }

}
